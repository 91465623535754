.root {
  align-items: center;
  display: flex;
}

.arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.arrow > div {
  align-items: center;
  display: flex;
}

.arrow.is-open {
  transform: rotate(90deg);
}

.filetype {
  display: flex;
}

.label {
  padding-inline-start: 8px;
}

.selected {
  background-color: rgba(0, 84, 255, 0.25);
}

.dragging {
  opacity: 0.5;
}
