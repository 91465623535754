@import 'src/assets/css/colors';
@import 'src/assets/css/animations';
.range-picker{
  .ant-picker-input {
    input {
      border: none!important;
    }
  }
}
.add-element-modal-wrapper {
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  height: 620px !important;
  min-height: 450px;
  max-height: calc(100vh - 90px);
  width: 100% !important;
  max-width: 750px;
  .footnote {
    padding: 0 20px;
  }
  .modal-body {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
  }
  .add-element-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    transition: width 3ms linear;
    .add-element-modal-body {
      flex: 1 1 auto;
      .active {
        background-color: white;
        outline: 3px solid rgb(80 157 80);
        box-sizing: border-box;
        border-radius: 6px !important;
        & + span + p {
          font-weight: 500 !important;
        }
      }
      input:disabled,
      textarea:disabled {
        opacity: 0.5;
        color: #b3b3b3;
      }
      .forecast-properties {
        flex-wrap: wrap;
        div {
          display: flex;
          align-items: center;
          width: 50%;
          margin-bottom: 0.5em;
          padding-left: 0.5rem;
          label {
            display: flex;
            align-items: center;
          }
          p {
            opacity: 0.7;
            margin-left: 10px;
          }
        }
        select {
          text-align: left;
        }
      }
    }
    .title {
      font-weight: 300;
      font-size: 13px;
      margin: 5px 0 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        // text-decoration: underline;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 15px;
      }
      em {
        display: flex;
        align-items: center;
        font-style: normal;
        cursor: pointer;
        svg {
          margin-right: 7px;
        }
      }
    }
    .buttons {
      padding-top: 10px;
      gap: 15px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 0;
      .active {
        background-color: #4f5a6f;
      }
      svg {
        font-size: 35px;
        margin-bottom: 10px;
        opacity: 0.2;
      }
      span.flex {
        flex-direction: column;
        align-items: center;
      }
      justify-content: space-between;
      padding-bottom: 10px;
      button {
        // animation: $scaleIn;
        width: 100%;
        height: 110px;
        background: transparent;
        justify-content: center;
        border: 1px solid #ffffff2b;
        &:hover {
          background-color: #28334a;
          svg {
            opacity: 1;
          }
        }
        &:focus {
          background-color: #28334a;
          border: 1px solid #ffffff2b !important;
          svg {
            opacity: 1;
          }
        }
      }
    }
    .form-wrapper {
      overflow-y: auto;
      position: relative;
      padding-left: 1px;
      flex: 1 1 auto;
      width: 100%;
      // animation: $scaleIn;
      .hourly {
        padding-bottom: 10px;
        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
        &::-webkit-scrollbar-track {
          margin-top: 10px;
          background: #000000;
        }
        &::-webkit-scrollbar-thumb {
          background: #ffffff;
          width: 2px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      &::-webkit-scrollbar {
        z-index: 999;
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: black;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background-color: white;
      }
      .form-body {
        // position: absolute;
        width: 100%;
        margin: auto;
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        .steps {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-bottom: 15px;
          span {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 100;
            background-color: #4f5a6f;
            &.active-step {
              background-color: #1c64f2;
            }
          }
          & + .mb-2 {
            height: 32px;
          }
        }
        .button.button-secondary {
          background-color: transparent;
          border: 1px solid #ffffff45;
          box-shadow: none;
          color: white;
          padding: 3px 10px;
          height: 32px;
          margin: 0;
          .label {
            color: white;
            font-weight: 100;
          }
        }

        .mb-2 {
          p {
            font-size: 12px;
            opacity: 0.7;
            margin-top: 7px;
            span {
              background-color: #42506c;
              padding: 3px 5px;
              border-radius: 4px;
              border: 1px solid #8c8c8c;
            }
          }
        }

        select {
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.6);
        }
        option {
          color: black;
        }
        #Input {
          label {
            input {
              background-color: transparent;
            }
          }
        }
        .element-title {
          text-transform: capitalize;
        }
        .toggler {
          display: flex;
          padding: 10px 0;
          .toggler-label {
            margin-right: 10px;
            font-size: 0.9rem;
            font-weight: 300;
          }
        }
        .dzu-dropzone {
          flex: 1 1 auto;
        }
      }

      h1 {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 0.3;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
      opacity: 0.3;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
      opacity: 0.3;
    }
    .select-map-header {
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 0.5em;
      p {
        & + div {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          margin-bottom: 0.5em;
          margin-right: 3px;
          margin-left: 3px;
          span {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            opacity: 0.7;
            svg {
              font-size: 21px;
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .map-modal-body {
      #Input {
        label {
          margin-bottom: 4px;
          margin-top: 8px;
        }
      }
      .map-buttons-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 13px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        justify-content: center;
        width: 100%;

        .map-btn {
          width: calc(33% - 10px);
          border-radius: 4px;
          overflow: visible;
        }
        button {
          display: flex;
          margin-top: 0;
          margin-bottom: 0;
          align-items: flex-start;
          border-style: solid;
          border-width: 0 0 0.5px;
          border-color: rgb(43, 52, 65);
          transition: all 200ms ease;
          width: 100%;
          height: 120px;
          cursor: pointer;
          overflow: hidden;
          background: transparent;
          padding: 0;
          &:hover {
            span {
              div {
                background-color: rgba(0, 0, 0, 0.5);
                transition: all 0.1s;
                padding-bottom: 15px;
              }
            }
          }
          span:empty {
            display: none;
          }
          span {
            padding: 0;
            height: 120px;
            width: 100%;
            position: relative;
            img {
              object-fit: cover;
            }
            div {
              padding-bottom: 10px;
              display: flex;
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              height: 100%;
              flex-direction: column;
              justify-content: flex-end;
              transition: all 0.1s;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) -13%,
                rgba(0, 0, 0, 0.6362920168) 86%
              );
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
              p {
                color: white;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  div[role='group'] {
    width: 100%;
    gap: 15px;
    margin-top: 5px;
    button {
      padding: 0;
      background-color: transparent;
      span {
        padding: 0;
      }
    }
    .focus\:ring-4:focus {
      --tw-ring-offset-shadow: none;
      --tw-ring-shadow: none;
      box-shadow: none;
    }
    &:focus {
      --tw-ring-shadow: 0 !important;
    }
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footnote {
      padding-left: 10px;
      display: flex;
      align-items: flex-end;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      width: -webkit-fill-available;
    }
  }
  .loading-indicator-wrapper {
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .select-loader {
    right: 10px;
    bottom: 10px;
  }
  .button-holder {
    white-space: nowrap;
    display: flex;
    align-items: center;
    button {
      padding: 0px 20px;
      color: white;
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .button.button-link {
      padding: 0;
    }
  }
  .gvnsp-background {
    // background-image: url('../../assets/images/map-bg-space.jpg');
    background-color: black;
    background-repeat: repeat;
  }
}
.icons-wrapper {
  padding: 20px 0;
  height: fit-content;
  overflow-x: scroll;
  background-color: black;
  margin-top: 10px;
  &::-webkit-scrollbar {
    z-index: 999;
    width: 2px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: black;
    height: 12px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: white;
    height: 5px;
    width: 2px;
  }
  .observed-icons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1 0 15%;
    margin: 0 30px;
    &:hover {
      cursor: pointer;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      &:hover {
        filter: drop-shadow(0px 0px 10px grey);
      }
      &.active-icon {
        filter: drop-shadow(0px 0px 10px grey);
      }
    }
  }
}
.next-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
  div:empty {
    display: none;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    // border-radius: 50%;
    // width: 50px;
    // height: 50px !important;
    // padding: 0;
    span:empty {
      display: none;
    }
    &:disabled {
      opacity: 0.5;
      display: flex;
      align-items: center;
    }
  }
}

.maptiler-geocoder-results {
  background: #37445e !important;
  color: white;
}

.maptiler-geocoder-results > div {
  background-color: #37445e !important;
}

.maptiler-geocoder-results > div:hover,
.maptiler-geocoder-results > div.selected {
  background: #47546e !important;
}

.maptiler-geocoder-results .item-name {
  color: #ffffff !important;
}

.maptiler-geocoder-results .item-context {
  font-size: 1em !important;
  color: #d6d6d6 !important;
}

.add-element-modal-wrapper.OBSERVED_DATA {
  .react-datepicker {
    background: #1f2a40;
    color: white;
    border: none;
    .react-datepicker__navigation {
      top: 12px;
    }
    .react-datepicker__header {
      border-bottom: 1px solid rgba(128, 128, 128, 0.1);
    }
    .react-datepicker__time-container {
      border-left: 1px solid rgba(128, 128, 128, 0.1);
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header {
      color: white;
    }
    .react-datepicker__day-name {
      color: #8d949f;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      background-color: #1a56db;
      border-left: none;
    }
    .react-datepicker__time-list-item--disabled {
      display: none;
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      background: #1f2a40;
      &::-webkit-scrollbar {
        z-index: 999;
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #1a1f6a;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background-color: white;
      }
    }
    .react-datepicker__day {
      color: white;
      font-weight: 600;
      &:hover {
        background-color: #1a56db;
      }
      &.react-datepicker__day--outside-month {
        font-weight: 100;
      }
    }
    .react-datepicker__day--disabled {
      color: #363636;
    }
    .react-datepicker__header {
      color: white;
      background: #1f2a40;
      min-height: 85px;
    }
  }
  .react-datepicker__input-container {
    display: flex;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
    input {
      cursor: pointer;
      width: 100%;
      margin-top: 1rem;
      text-align: center;
      border-radius: 6px;
      // margin: auto;
      border: none !important;
      background-color: #1a56db !important;
    }
  }
}

.observed-select {
  width: 100%;
  padding: 5px 10px;
  flex: 0 0 100%;
  text-align: center;
  color: white;
  background: #1c64f2;
  background-color: transparent;
  margin-bottom: 3px;
  font-size: 12px;
  &:focus {
    border-color: #fff !important;
  }
  option {
    color: black;
  }
}
.loading:after {
  content: ' .';
  animation: $searchDots;
}

//#1f2a40
.icon-picker-list {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: auto;
  background: #05152b;
  padding: 15px;
  border-radius: 8px;
  z-index: 1;
  -webkit-box-shadow: 5px 5px 10px -6px rgba(136, 133, 133, 0.75);
  -moz-box-shadow: 5px 5px 10px -6px rgba(136, 133, 133, 0.75);
  box-shadow: 5px 5px 10px -6px rgba(136, 133, 133, 0.75);
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #00000000;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    width: 3px;
  }
  .icon-list {
    display: flex;
    margin: 10px 0;
    justify-content: flex-start;
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 3px;
    &:hover {
      background: rgb(64, 70, 87);
    }
    .picker-lottie-wrap {
      max-width: 50px;
    }
  }
  img,
  object {
    height: 50px;
    max-width: 50px;
    object-fit: contain;
    padding-right: 10px;
  }
}
.icon-box {
  position: relative;
  width: 100%;
  &.active-icon {
    background-color: rgb(26, 86, 219);
  }
  .icon-picker-list {
    background: #05152b;
    padding: 15px;
    border-radius: 8px;
    z-index: 9;
    position: absolute;
    .icon-list {
      display: flex;
      margin: 10px 0;
    }
    img {
      width: 50px;
    }
  }
}

.add-element-drop-down {
  position: absolute;
  z-index: 2;
  min-width: 200px;
  padding: 10px;
  left: 5px;
  top: 40px;
  background: #182c48;
  border-radius: 5px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  button {
    width: 100%;
    background-color: transparent;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    color: white;
    padding: 0;
    padding-left: 1rem !important;
    margin: 0;
    height: 37px;
    border-radius: 4px;
    span {
      color: white;
      margin-left: 15px;
    }
    svg {
      margin-right: 15px;
      opacity: 0.6;
      color: white;
    }
    &:hover {
      background-color: #1e429f;
      svg {
        opacity: 1;
      }
    }
  }
}

.AddMapElement__gvnsp-form {
  padding: 10px 0px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  .grid-item {
    background-color: transparent;
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
