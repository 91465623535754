#Input {
  display: flex;
  width: 100%;
  flex-direction: column;
  label {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 8px;
    &:empty {
      display: none;
    }
    span {
      color: #fa5656;
      margin-right: 3px;
    }
  }
  textarea,
  input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    border-color: rgb(209 213 219);
  }
  input {
    height: 32px;
    text-align: left;
  }
  .helper-text {
    font-size: 10px;
    color: #fa5656;
  }
}
input::placeholder {
  // padding-right: 10px;
  // padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #050c43;
  opacity: 0.3;
}
