.properties__tabs_wrapper {
  div[aria-label='Tabs'] {
    display: flex;
    justify-content: space-around;
    border: none;
    .text-blue-600 {
      color: unset;
    }
    .border-blue-600 {
      border-color: unset;
    }
    button {
      outline: none !important;
    }
  }
}

.bg-yellow-400 {
  background-color: #fa5656;
}

div[data-testid='tooltip'] {
  z-index: 9;
}
div[data-testid='tooltip'].bg-gray-900 {
  background-color: #1f2a40;
  div {
    background-color: #1f2a40;
  }
}

.dark {
  .dark\:bg-gray-700 {
    background-color: #1f2a40;
  }
}

@keyframes FADE_IN {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FADE_OUT {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.maptiler-geocoder-results {
  z-index: 9999999999 !important;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom {
  top: 0.5em;
  left: 0.5em;
}

.ol-control {
  position: absolute;
  border-radius: 4px;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: gray;
  font-weight: bold;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: white;
  border: none;
  border-radius: 2px;
}

// Full screen styles
.fullScreen_container {
  position: fixed;
  top: 0;
  left: 0;
  // min-width: 100vw !important;
  // min-height: 100vh !important;
  background-color: black !important;
  // scale: 1.222;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreen_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 99999;
  background-color: black !important;
}

.noFullScreen_container {
  position: absolute;
  /*top: 0;
  left: 0;*/
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.project-toast {
  font-size: 16px;
  width: 600px;
  left: calc(-50%);
}
