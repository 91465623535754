/* [3] */
.ws-card {
  border-radius: 20px;
  border: 1px solid rgb(204 204 204 / 40%);
  position: relative;
  overflow: hidden;
  box-shadow: 4px 4px 10px -10px rgb(0 0 0 / 75%);

  &:hover {
    box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);
    transition: all 0.2s;

    .ws-card-speed-dial {
      visibility: visible;
      border: 1px solid rgba(204, 204, 204, 0.4);
    }
  }

  img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    object-position: center;
  }

  .ws-card-tooltip {
    position: absolute;
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.48);
    color: white;
    word-wrap: break-word;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid black;
    pointer-events: none;
    z-index: 9;
  }

  .ws-card-content {
    padding: 10px 15px;
    position: relative;

    .ws-card-title {
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        color: #3a36db;
      }
    }

    .no-data {
      font-style: italic;
      font-weight: 100;
    }

    .ws-card-desc {
      margin: 5px 0 10px 0;
      font-weight: 100;
      color: rgba(128, 128, 128, 1);
      border-top: 1px solid rgba(128, 128, 128, 0.1);
      border-bottom: 1px solid rgba(128, 128, 128, 0.1);
      padding: 10px 0;
      //   height: 4.2rem;
      //   min-height: 4.2rem;
      box-sizing: border-box;
      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .ws-card-date {
      font-weight: 100;
      font-size: 0.8rem;

      span {
        font-weight: bold;
      }
    }
  }
}

.ws-card.add-project {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in;
  background-color: #f2f2f236;
  width: 100%;
  height: 100%;
  min-height: 348px;
  min-width: 260px;

  svg {
    font-size: 40px;
    margin: 0.3em auto;
    transition: all 0.1s ease-in;
    position: relative;
    top: 0em;

    path {
      fill: #333;
    }
  }

  h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px;
    transition: all 0.1s ease-in;
  }

  &:hover {
    background-color: #ffffff;

    h2 {
      font-weight: 600;
      transition: all 0.1s ease-in;
    }

    svg {
      position: relative;
      top: -0.1em;
      transition: all 0.1s ease-in;
      font-size: 43px;

      path {
        fill: rgb(58 54 219);
      }
    }
  }
}

.disabled-project.ws-card::before{
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background: white;
  cursor: not-allowed;
  z-index: 2;

  img {
    cursor: not-allowed;
  }

  .ws-card-title {
    cursor: not-allowed;

    &:hover {
      color: inherit;
    }
  }
}

.disabled-project.ws-card {
  &:hover {
    box-shadow: none
  }
}

.font-card-wrapper {
  padding: 1rem;
  .ws-action-list {
    border-radius: 4px;
  }
  .title-wrapper {
    margin-bottom: 1rem;
    h2 {
      font-size: 22px;
      line-height: 1.4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .ws-card-desc {
      font-size: 16px;
      opacity: 0.7;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .date-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ws-card-date {
      opacity: 0.5;
      & + span {
        text-transform: uppercase;
      }
    }
  }

  .font-snippet {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 3rem;
    max-height: 220px;
    color: #000000c9;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &::after {
      content: "";
      display: block;
      height: 1em;
    }
  }
}
#fontSelect {
  padding: 0px 35px 0px 5px;
  border-radius: 4px;
  border-color: #ebebeb;
  color: #00000094;
  margin-bottom: 5px;
}