.similar-content {

  .title {
    font-size: 22px;
    font-weight: 400;
    color: #06152b;
    margin-bottom: 25px;
  }


  .text-with-line {
      overflow: hidden;
  }
  .text-with-line:before,
  .text-with-line:after {
      background-color: #CFCFCF;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
  }
  .text-with-line:before {
      right: 4rem;
      margin-left: -50%;
  }
  .text-with-line:after {
      left: 4rem;
      margin-right: -50%;
  }
  }


