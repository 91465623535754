.wrapper {
  padding: 20px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  span {
    font-size: 20px;
  }

  button {
    width: 100px;
    height: 30px;
    border-radius: 10px;
  }
}

.delete {
  background-color: #fa5656;
  color: #fff;
  margin-right: 20px;
  width: 100px;
  height: 30px;
  border-radius: 10px;

  &:hover {
    background-color: darken($color: red, $amount: 3%);
  }
}

.cancel {
  border: 1px solid gray;
  width: 100px;
  height: 30px;
  border-radius: 10px;

  &:hover {
    background-color: darken($color: white, $amount: 3%);
  }
}
.successWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 30px;
    height: 30px;
    fill: green;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
  }
}
.success {
  background-color: green;
  color: #fff;
  margin-right: 20px;
  width: 100px;
  height: 30px;
  border-radius: 10px;

  &:hover {
    background-color: darken($color: green, $amount: 3%);
  }
}
