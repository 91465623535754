  .ant-list-item-meta-title {
    font-size: 18px !important;
    word-wrap: break-word;
}
.ant-list-item-meta-description {
    margin-top: 8px;
    font-size: 12px !important;
    color: gray !important; 
}
.ant-list-item-meta {
    align-items: center !important;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    align-items: center;
    padding: 0;
}
.ant-collapse-content-box {
    padding: 0 !important;
    margin-top: 16px;
}
.checkout-input {  
    padding: 0 0 0 0px;
    & input {
        text-align: center;
    }
}