.cf-modal {
  border-radius: 7px;
  position: relative;
  width: auto;
  height: min-content;
  margin: auto auto;
  left: 0;
  right: 0;
  padding: 2em;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: all;
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 30%);
  border: 1px solid #e5e7eb;
  h3 {
    font-size: 17px;
    display: block;
    max-width: 330px;
    margin: 0 auto;
    text-align: center;
  }
  .cf-buttons {
    display: flex;
    margin: 2rem 0 0;
    justify-content: center;
    button {
      text-decoration: unset;
    }
  }
}

.cf-confirm,
.cf-cancel {
  display: flex;
  line-height: 1.2rem;
  align-items: center;
  padding: 10px 25px;
  border-radius: 20px;
  font-size: 1.2rem;
}
.cf-confirm {
  background: #3a36db;
  color: white;
  &:hover {
    background: #1a1f6a;
  }
}
.cf-cancel {
  text-decoration: underline;
  color: rgba(6, 21, 43, 0.5);
  &:hover {
    color: #1a1f6a;
  }
}

.details-modal {
  .schedule-list {
    .ws-temp-title {
      display: none;
      h1 {
        display: none;
      }
    }
 
  }
  .buttons-container {
    margin-top: 1rem;
    .tab-content-wrapper {
      min-height: 350px;
    }
    .tab-buttons {
      overflow: auto !important;
      .tab-btn {
        border: none;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px 30px;
        height: unset;
        line-height: 1;
        &.tab-active {
          background-color: #2176ff;
        }
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  div.p-6 {
    max-height: 500px;
    overflow: auto;
  }
  h2 {
    & + p {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
  h3,
  h2 {
    text-transform: uppercase;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;;
    padding: 0 5px;
  }
  .dates {
    display: flex;
    align-items: center;
    opacity: 0.8;
    span {
      font-weight: normal;
      em {
        font-weight: 100;
        font-style: normal;
      }
    }
  }
  .schedule-wrapper {
    // margin-top: 1.5rem;
    // padding-top: 1rem;
    padding-left: 3px;
    h3 {
      padding-left: 0;
    }
  }
  .md-date {
    margin-left: 1rem;
    font-size: 16px;
  }
}

.schedule-card {
  border-radius: 10px;
  max-width: unset;
  background-color: transparent;
  border: none;
  box-shadow: rgba(1, 1, 1, 0.07) 1px 1px 5px 1px;
  border: 1px solid #e5e7eb;
  padding: 1.5rem;
  font-size: 17px;
  margin-bottom: 1rem;
  .schedule-and-actions {
    display: flex;
    align-items: center;
    .schedule-actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 1rem;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px 0 7px;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  .schedule {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    opacity: 0.8;
    span {
      font-weight: normal;
      font-size: 16px;
      em {
        font-weight: 100;
        font-style: normal;
      }
    }
    .md-date {
      margin-left: 0;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.va-list,
.sch-list {
  margin-top: 0;
  padding-top: 1rem;
  padding-left: 3px;
  h3 {
    color: black;
  }
  .assembly-status {
    position: static;
    width: 100%;
  }
}
.sch-list {
  margin-bottom: 3rem;
}
button.show-cron-btn {
  margin: 1rem auto 1rem;
  gap: 10px;
  width: auto;
  border: 1px solid #2176ff !important;
  color: #2176ff;

  span {
    color: #2176ff !important;
  }
}
.cron-modal {
  .cron-wrapper,
  .render-video-wrapper {
    margin-top: 0.5rem;
    .button-primary {
      svg {
        font-size: 23px;
      }
    }
  }
  .cron_builder {
    border-radius: 10px;
    max-width: unset !important;
    background-color: transparent !important;
    border: none;
    box-shadow: rgb(1, 1, 1, 0.07) 1px 1px 5px 1px;
    border: 1px solid #e5e7eb !important;
    margin-top: 1rem;

    .nav {
      a.nav-link {
        border-radius: 4px;
        color: #000000;
        font-weight: 600;
        text-transform: uppercase;
      }
      a.nav-link.active {
        border-color: transparent transparent transparent;
        background-color: #2176ff1c;
      }
    }
    .cron_builder_bordering {
      border: none;
      input[type='radio'] {
        min-width: 28px;
        border-radius: 50%;
      }
      input[type='checkbox'] {
        min-width: 15px;
        height: 15px;
      }
      .tab-pane {
        margin-bottom: 1rem;
      }
    }
    .well {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      // border: 1px solid transparent;
      box-shadow: none;
      .text_align_right {
        width: unset !important;
      }
    }
    .cron-builder-bg {
      background-color: #f5f5f5;
      color: black;
      font-weight: 500;
    }
    .nav-link.active,
    .tab-pane.active {
      outline: unset;
    }
  }
  #Input input {
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding: 0px 5px;
    min-height: 28px;
  }
  #Input label {
    font-size: 16px;
    margin-top: 0;
    opacity: 0.6;
  }

  .ant-input {
    border-radius: 6px;
    border-color: #e5e7eb;

    :hover {
      border-color: #4096ff !important;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e5e7eb;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e5e7eb;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e5e7eb;
    }
  }
}
