@import "../../assets/css/colors.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 5px 5px 5px 10px;
  flex: 1 1 30%;
  max-width: 1220px;

  img {
    cursor: pointer;
  }
  .search-header {
    display: flex;
    align-items: center;
    height: 36px;
    width: 555px;
    margin: auto;
    background-color: #fafafa;
    border-radius: 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    .dropdown {
      background-color: transparent;
      height: 36px;
      min-height: unset;
    }
    .mp-input {
      input {
        background-color: transparent;
      }
    }
  }
  img {
    object-fit: contain;
    max-width: 240px;
    @media(max-width: 500px) {
      max-width: 140px;
    }
  }
  .dropdown-placeholder,
  .dropdown-item {
    color: rgba(6, 21, 43, 0.55) !important;

    &:hover {
      color: rgba(6, 21, 43, 0.85) !important;
    }
  }
}