.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  min-width: 280px;
  flex: 1;
  height: 100%;
  .sidebar-logo {
    padding: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .ws-header-title {
      margin-top: 5px;
      background: rgba(0, 0, 0, 0.5);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  .sidebar-content {
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    box-shadow: 4px 0 10px -11px rgb(0 0 0 / 75%);
    .sidebar-main,
    .sidebar-help {
      .sidebar-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        padding: 0 10px;
        margin-top: 3px;
        font-weight: 400;
        cursor: pointer;
        &.active-item {
          color: #3a36db;
        }
        .sidebar-item-content {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          cursor: pointer;
          padding: 10px 25px;
          span {
            margin-left: 8px;
            small {
              display: block;
              opacity: 0.6;
              line-height: 1;
            }
          }
        }
        &:hover {
          color: #3a36db;
        }
      }
      .sidebar-subItems {
        cursor: pointer;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s linear;
        margin-left: 45px;
        &.open {
          max-height: 680px;
          transition: max-height 0.2s linear;
          position: relative;
          &:before {
            position: absolute;
            content: '\00A0';
            border-left: 1px solid black;
            top: 0;
            bottom: 22px;
            width: 1px;
          }
        }
        .sidebar-item {
          border-radius: 0;
          justify-content: start;
          padding-left: 0;
          .sidebar-item-content {
            padding-left: 0;
            align-items: center;
          }
          &:before {
            content: '\00A0';
            border-bottom: 1px solid black;
            height: 1px;
            width: 10px;
          }
        }
      }
    }
  }

  .sidebar-main {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    flex: 1 1 auto;
  }

  .sidebar-bottom {
    display: flex;
    align-items: flex-end;
    margin-bottom: 70px;
  }

  .version {
    text-align: center;
    background-color: #fff;
    opacity: 0.3;
  }
}
