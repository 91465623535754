.right-menu {
  button {
    span {
      color: #000;
    }
  }
}

.corner-icon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: x-small;
  font-weight: bold;
  color: white;
  background-color: red;
  border: 1px solid white;
  border-radius: 8px;
  top: 2px;
  right: 2px;
}
.ant-select {
  min-width: 100px;
}
