#Lane-flyover {
  width: 100%;
  position: relative;
  box-sizing: content-box;
  transition: all 0.3s linear;
}
.react-datepicker__time-list-item.time-style.react-datepicker__time-list-item--disabled {
  display: none;
}
#Lane {
  width: 100%;
  border-bottom: 1px transparent dotted;
  position: relative;
  box-sizing: content-box;
  height: 30px;
  transition: all 0.3s linear;
  .rail {
    width: 101%;
    height: 1px;
    //margin-left: -30px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.49) 20%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    position: relative;
    top: 12px;
  }
  .segment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(26, 106, 61);
    border-radius: 3px;
    height: 24px;
    //margin: 6px 0 0;
    position: absolute;
    resize: horizontal;
    color: white;
    text-align: center;
    box-sizing: border-box;
    .rc-slider-mark .rc-slider-mark-text:first-child {
      display: none;
    }
    &:hover {
      .layer-hover {
        visibility: visible;
      }
    }
    .layer-hover {
      background-color: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      position: relative;
      margin: auto;
      top: -25px;
      padding: 5px;
      border-radius: 5px;
    }
    &.grib {
      opacity: 0.8;
      background: linear-gradient(135deg, #0054ff 100%, transparent 25%) -25px -10,
        linear-gradient(225deg, #0054ff 100%, transparent 25%) -25px -10,
        linear-gradient(315deg, #0054ff 100%, transparent 25%),
        linear-gradient(45deg, #0054ff 100%, transparent 25%);
      background-size: 20px 20px;
      background-color: #5454ff;
    }
    &.radar {
      opacity: 1;
      background: #000000
        repeating-linear-gradient(-45deg, #044610 5px, #044610 5px, #000000 10px, #000000 10px);
    }
    &.satellite {
      opacity: 1;
      background: #055222
        repeating-linear-gradient(-45deg, #ab7f05 5px, #ab7f05 5px, #055222 10px, #055222 10px);
    }
  }
  .segment-project {
    overflow: hidden;
    height: 40px;
    background-color: rgba(26, 106, 61, 1);
    border-radius: 3px;
    position: absolute;
    border: solid black;
    border-width: 0 1px;
    color: white;
    text-align: center;
    display: flex;
    line-height: 72px;
    align-items: center;
  }
  &.sub-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 0;
    text-transform: capitalize;
    text-align: right;
    padding-right: 5px;
    font-size: 0;
    overflow: hidden;
    transition: all 0.2s;

    &.open {
      height: 30px;
      font-size: 0.8rem;
    }
  }
}
