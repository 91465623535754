@import 'src/assets/css/animations';

.blink {
  animation: $blink;
  color: #fa5656;
}

.recorder-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  @apply my-2;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  border: 2px solid rgba(255, 255, 255, 0.6);
  width: 80px;
  height: 80px;
  @apply p-2;
  @apply mx-0.5;
  -webkit-box-shadow: 3px 5px 5px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 5px 5px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 5px 5px -6px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: rgba(6, 21, 43, 0.4);
  }
}

.indicator {
  display: flex;
  align-items: center;
}

.select {
  width: 70px;
  padding: 5px;
  font-size: 16px;
  line-height: 24px;
  min-height: 32px;
  background-color: rgba(103, 130, 153, 0.54);
  border-color: #6b7280;
  color: #8e8e8e;
  border-radius: 6px;

  option {
    background: #3a4d61;
  }
}

.icon-button {
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }
}

.search-result {
  top: -7px;
  max-height: 150px;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.07);
  padding: 5px 10px 10px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: #1a56db;
  border-width: 1px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #00000000;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .result {
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.location-wrapper {
  .search-result {
    position: absolute;
    background-color: #232f47;
    top: 100%;
    width: 100%;
    z-index: 1;

    .result {
      padding: 0 5px;
    }
  }
}

.form-body {
  width: calc(100% - 5px);
  margin: auto;
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;

    span {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 100;
      background-color: #4f5a6f;

      &.active-step {
        background-color: #1c64f2;
      }
    }

    & + .mb-2 {
      height: 32px;
    }
  }

  .button.button-secondary {
    background-color: transparent;
    border: 1px solid #ffffff45;
    box-shadow: none;
    color: white;
    padding: 3px 10px;
    height: 32px;
    margin: 0;

    .label {
      color: white;
      font-weight: 100;
    }
  }

  .mb-2 {
    p {
      font-size: 12px;
      opacity: 0.7;
      margin-top: 7px;

      span {
        background-color: #42506c;
        padding: 3px 5px;
        border-radius: 4px;
        border: 1px solid #8c8c8c;
      }
    }
  }

  select {
    background-color: transparent;
    border: 1px solid rgb(77, 86, 104);
  }

  option {
    color: black;
  }

  #Input {
    label {
      input {
        background-color: transparent;
      }
    }
  }

  .element-title {
    text-transform: capitalize;
  }

  .toggler {
    display: flex;
    padding: 10px 0;

    .toggler-label {
      margin-right: 10px;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  .dzu-dropzone {
    flex: 1 1 auto;
    margin-top: 1em;
  }
}

.record-video-actions {
  button {
    height: 32px;
  }
}

.record-modal {
  min-height: calc(100vh - 100px);
  min-width: calc(100% - 100px);
  select.select {
    color: white;
    min-width: 100px;
  }
  .modal-body {
    height: calc(100% - 80px) !important;
    .flex.flex-col {
      width: 100%;
      height: 100%;
    }
  }

  video {
    // max-width: 400px;
    margin-top: 3rem;
    height: auto;
    border-radius: 10px;
  }

  .audio-wrapper {
    flex: 1 1 auto;

    audio {
      margin-top: 50px;
    }

    .audio-btn {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
    }
  }
}

.create-project-modal {
  .add-project-modal-header {
    padding: 0.25rem;
  }

  .add-project-modal-footer {
    display: flex;
    align-items: center;

    button {
      margin: 0;
      margin-right: 1em;

      span {
        margin: 0;
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 1rem 1rem 1rem !important;
  }

  .ant-input-password {
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    padding: 0 1rem;
  }

  .ant-collapse-header {
    padding-left: 8px !important;
    height: 37px;
  }

  .ant-input,
  .ant-input-number {
    border-radius: 6px;
    border-color: #e5e7eb;
    height: 37px;

    :hover {
      border-color: #4096ff !important;
      box-shadow: #4096ff !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e5e7eb;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e5e7eb;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e5e7eb;
    }
  }
}

.add-layers-wrapper {
  width: 100% !important;
  overflow: hidden !important;
  max-width: 960px;
  display: flex;
  flex-direction: column;

  .grid-item,
  .input-wrap {
    height: 23px;
  }

  .grid-item {
    background-color: transparent;
  }

  .capitalize {
    padding-left: 10px;
  }

  .prop-button-w100 {
    button {
      height: 32px;
      margin-top: 3em;
      border: 1px solid #3c4c62;
      text-transform: uppercase;

      &:hover {
        background-color: #2176ff;
      }

      svg {
        margin-right: 8px;
        font-size: 18px;
      }
    }
  }

  label {
    min-width: 120px;
    color: #ffffffd6;
  }

  .grid.prop-grid-4 {
    // grid-template-columns: 120px auto 120px auto;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-right: 20px;
    grid-column-gap: 20px;
    row-gap: 20px;
    margin-right: 0;

    .grid-item {
      &:empty {
        height: 0;
      }
    }

    .grid-item:nth-child(odd) {
      max-width: 170px;
    }

    .grid-item:nth-child(even) {
      justify-content: flex-end;
    }
  }

  .modal-body {
    height: unset;
    flex: 1 1 auto;
    padding: 1em 2em 2em !important;

    h3 {
      color: white;
      text-transform: uppercase;
      font-weight: 300;
      margin-bottom: 1em;
      margin-top: 1em;
      font-size: 20px;
    }

    textarea,
    input:not([type='checkbox']) {
      //   border: none !important;
      background: transparent;
      //   border-bottom: 1px solid #273c59 !important;
      //   border-radius: 0 !important;
      text-align: right;

      &:hover {
        border-color: #273c58 !important;
      }

      &:focus {
        border-color: #2176ff !important;
        box-shadow: none;
      }

      &:focus-visible {
        outline: none;
      }
    }

    select {
      //   border: none;
      background-color: transparent;
      //   border-bottom: 1px solid #273c59 !important;
      //   border-radius: 0;
      border: 1px solid rgb(77, 86, 104);
      text-align: left;
      padding-right: 35px;
      padding-left: 10px;
      color: #ffffff;
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
      min-height: unset;
      font-size: 14px;
      margin-left: auto;

      &:hover {
        border-color: #273c58;
      }

      &:focus {
        // border: none;
        border-bottom: 1px solid #2176ff;
        // box-shadow: none;
      }
    }

    option {
      background: #3a4d61;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;

    .footnote {
      padding-left: 10px;
      display: flex;
      align-items: flex-end;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      width: -webkit-fill-available;
    }

    button {
      margin: 0;
      margin-right: 0.5rem;

      svg {
        margin-right: 10px;
      }
    }
  }

  .prop-switcher {
    display: flex;

    button {
      width: 49px;
      margin-right: -13px;

      .border-gray-200.bg-gray-200 {
        background-color: #050f1e;
        border-color: #3c4c62;
      }
    }
  }

  .rc-slider {
    margin-right: 20px;
    margin-left: 20px;
    width: 70px;
    min-width: 70px;

    .rc-slider-track {
      background-color: #1a56db;
      cursor: pointer;
      margin-top: -2px;
      padding: 4px 0;
    }
  }

  .rc-slider-disabled {
    background-color: transparent;
    opacity: 0.2;
  }

  .tb-box-wrapper {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: relative;
    width: 100px;

    div {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }

    div {
      position: absolute;
    }

    div.tb-box {
      position: static;
    }

    input {
      max-width: 30px;
      min-width: unset;
      text-align: center;
    }

    .padding-top-box {
      top: -10px;
    }

    .padding-bottom-box {
      bottom: -10px;
    }

    .padding-right-box {
      right: -10px;
    }

    .padding-left-box {
      left: -10px;
    }
  }

  .font-alignment {
    width: 100%;

    button {
      background-color: #060b12;
      border-color: #2b3441;
      height: 25px;
      width: 100%;

      span {
        align-items: center;
        justify-content: center;

        svg {
          color: white;
        }
      }

      &:focus {
        outline: 1px solid #1a56db;
      }
    }
  }
}

#FramesRange {
  width: 100%;
  padding: 0 30px;
  margin-top: 20px;

  .frames-range {
    width: calc(100% - 70px);
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
    white-space: nowrap;
  }
}

.property-card {
  animation: $scaleIn;
  max-width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 7px 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  max-height: 157px;

  .icon-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .icon-arrows {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background-color: white;
        color: #1a1f6a;
        border-radius: 3px;
      }
    }
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  div:nth-child(2) {
    font-weight: 600;
    opacity: 0.5;
    font-size: 30px;
    color: white;
  }

  &:hover {
    background-color: rgba(26, 86, 219, 0.5);
  }

  &.active-card {
    background-color: rgba(26, 86, 219, 1);
  }

  .prop-observed-val {
    text-align: center;
    font-size: 50px;
    padding: 0 10px;
    fill: #000;
  }

  .prop-icon-val {
    display: flex;
    justify-content: center;
    height: 100%;
    width: auto;

    .icon-arrows {
      color: rgba(26, 31, 106, 1);
      padding: 3px;
    }

    img {
      height: 93px;
      width: auto;
      object-fit: contain;
    }
  }
}

.date {
  position: sticky;
  top: 0;
  font-weight: bold;
  text-align: center;
  font-size: large;
}

.rc-slider-handle.rc-slider-handle-1 {
  top: -20px !important;
  transform: translateX(-50%) rotate(135deg) !important;

  div {
    top: 0 !important;
    transform: translateX(-50%) rotate(-135deg) !important;
  }
}

.rc-slider-mark {
  .rc-slider-mark-text {
    &:first-child {
      top: -5px !important;
    }
  }
}

.assembly-status {
  position: absolute;
  z-index: 8;
  top: 40px;
  background: #182c48;
  border-radius: 5px;
  right: 100px;
  width: 1360px;
  color: white;
  padding: 2rem;
  font-size: 13px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  h1 {
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    margin-bottom: 1.5rem;
    font-size: 25px;
  }

  .flex.flex-col {
    margin-bottom: 0.5rem;

    .flex {
      gap: 1rem;

      .assembly-col {
        min-width: 155px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .flex-center {
          display: flex;
          align-items: center;

          p {
            margin-left: 10px;
          }
        }

        button {
          height: 23px;
        }

        svg {
          color: white;
          font-size: 30px;
        }

        &-no-min-w {
          min-width: 20px !important;
        }
      }
    }
  }

  .assembly-status-head {
    p {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 17px;
      margin-bottom: 0.5rem;
    }
  }

  .assembly-status-body {
    .txt {
      opacity: 0.8;
    }
  }

  .actions {
    margin-top: 2rem;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin: 0;
      margin-left: 1rem;

      svg {
        margin-right: 10px;
        font-size: 24px;
      }
    }
  }
}

.AddCitiesModal__buttons-wrap {
  border: 1px solid #fff;
  border-radius: 5px;
  max-width: calc(50% - 2rem);
}

.AddCitiesModal__modal-wrap {
  height: auto !important;
  min-width: 700px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.AddCitiesModal__caret {
  position: absolute;
  right: 10px;
  top: 5px;
}

.add-city-modal {
  .search-result {
    text-align: left;
  }

  flex: 1 1 auto;

  input {
    color: white;

    &::-webkit-input-placeholder {
      /* Edge */
      color: white;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    &::placeholder {
      color: white;
    }
  }

  .tab-content-wrapper {
    background-color: transparent !important;
    padding: 1rem;
    border: 1px solid #3b4654;
    margin-top: -1px;
  }

  .buttons-container {
    height: auto;

    .tab-buttons {
      overflow: unset;

      .tab-btn {
        margin-right: 0;
        height: 32px;
        max-height: 32px;
        box-sizing: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #3b4654;

        &.tab-active {
          background-color: #1f2a40;
        }
      }
    }
  }
}

.bias-filer-results {
  h3 {
    margin-bottom: 0.5rem !important;
  }
}

.add-cities-modal-wrapper {
  .subheader {
    border-bottom: 1px solid #3b46546e !important;
    margin-top: 0 !important;
  }

  input {
    background-color: rgba(103, 130, 153, 0.54) !important;
  }

  .search-result {
    position: absolute;
    top: 62px;
    width: 100%;
    z-index: 1;
    background: #465970;
  }
}
@media only screen and (max-width: 1400px) {
  .record-modal video {
    max-width: 400px;
  }
}
Row {
  Col {
    display: flex;
    flex-direction: column;
  }
}

.MapEventLayersModal__row {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.MapEventLayersModal__item {
  flex: 1;
}

.MapEventLayersModal__palette {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  label {
    min-width: fit-content;
    margin-right: 5px;
  }
}

.MapEventLayersModal__frames {
  margin-top: 1rem;
}
