.root {
  align-items: flex-start;
  background-color: #1967d2;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
  0 0 0 1px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  max-width: 250px;
  /* pointer-events: none; */
}

.item {
  align-items: center;
  color: #fff;
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
}

.icon,
.label {
  align-items: center;
  display: flex;
}
