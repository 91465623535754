.buttons-container {
  height: 29px;
  .tab-buttons {
    display: flex;
    overflow-x: scroll;
    box-sizing: content-box;
    overflow-y: hidden;

    .tab-btn {
      user-select: none;
      width: auto;
      white-space: nowrap;
      height: 28px;
      margin-right: 5px;
      margin-left: 0;
      border-style: solid;
      border-width: 0.5px;
      border-bottom: none;
      border-color: #2b3441;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #050f1e;
      color: #fff;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      padding: 3px 60px 3px 60px;
      opacity: 0.3;
      cursor: pointer;
      line-height: 1.9;
      .tab-btns {
        gap: 5px;
        position: absolute;
        right: 10px;
        top: 8px;
        display: none;
      }
      &:hover {
        // opacity: 0.8;
        .tab-btns {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .small-tab {
      height: 29px;
      margin-right: 5px;
      margin-left: 0;
      border-style: solid;
      border-width: 0.5px;
      border-color: #2b3441;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #050f1e;
      border-bottom: none;
      color: #fff;
      font-size: 14px;
      font-weight: 900;
      text-align: center;
      position: relative;
      display: inline-block;
      text-decoration: none;
      padding: 9px 9px;
      cursor: pointer;
    }
    .tab-active {
      background-color: #06152b;
      box-sizing: content-box;
      height: 23px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 3px 60px 3px 60px;
      opacity: 1;
      z-index: 1;
      .delete-scene {
        svg {
          opacity: 0.5;
          path {
            color: #ffffff;
          }
        }
        &:hover {
          svg {
            opacity: 1;
            path {
              color: #ff5b5b;
            }
          }
        }
      }
    }
  }
}
.click-to-add-scene {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.tab-content-wrapper {
  height: calc(100% - 30px);
  height: -moz-calc(100% - 30px);
  height: -webkit-calc(100% - 30px);
}
.pulse {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: pulse 1s infinite; /* Animation lasts 2 seconds, repeats infinitely */
  opacity: 0.8;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 1;
  }
}
