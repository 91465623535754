.schedule-list {
  .ant-btn.ant-btn-primary.ant-btn-lg {
    height: 32px;
    padding: 5px 15px !important;
    display: flex;
    align-items: center;
    @media(min-width: 1536px) {
      padding: 5px 13px !important;
    }
  }
  h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
  }

  p {
    font-size: 17px;
    opacity: 0.8;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 1.2;
  }

  span {
    b {
      font-weight: 600;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 2px 10px;
      display: flex;
      align-items: center;

      svg {
        color: white;
        margin-right: 5px;
      }
    }
  }

  .icon-sch-list {
    background: #2176ff12;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    outline: 6px solid #eff5ff;

    svg {
      font-size: 40px;
      color: #2176ff;
    }

    &.inactive {
      svg {
        color: #fa5656;
      }
    }
  }

  table {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;

  h3 {
    text-transform: uppercase;
    padding: 0;

    a {
      text-decoration: underline;
      color: #455060;
    }

    a:hover {
      color: #69b1ff;
    }
  }
}

.schedule-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef5ff;
  border-radius: 10px;
  width: 47px;
  height: 47px;

  svg {
    font-size: 30px;
  }

  &.pending {
    svg {
      color: grey;
    }
  }

  &.in.progress {
    svg {
      color: #1677ff;
    }
  }

  &.finish {
    svg {
      color: #57c061;
    }
  }
}

.preparation-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  button {
    padding: 0;
    width: auto !important;
    height: auto;
    color: grey;

    &.WARNING {
      svg {
        color: #faad14;
        font-size: 13px;
      }
    }

    &.ERROR,
    &.APP_ERROR {
      svg {
        color: #ff4d4f;
        font-size: 13px;
      }
    }
  }
}

.rendering-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;

  small {
    text-transform: uppercase;
  }

  .ant-progress {
    display: flex;
    align-items: center;
  }
}

.ant-modal-confirm-btns {
  button.ant-btn-primary {
    background-color: #1677ff !important;
  }

  button.ant-btn-primary:hover {
    opacity: 0.8;
  }
}

.details-modal {
  a:hover {
    color: #1677ff;
  }
}

.footnote{
  font-size: 12px;
  opacity: 0.7;
}
.ant-select {
  &-selector {
    border-radius: 6px !important;
  }
}