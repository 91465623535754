.board {
  flex: 1;
  display: flex;
  position: relative;
  height: -webkit-fill-available;
  max-height: 200px;
  margin-top: 40px;
  .story-card {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    top: 0;
    align-items: center;
    background-color: rgba(26, 106, 61, 0.5);
    border: 0.5px solid rgba(128, 128, 128, 0.38);
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: inset 0 0 6px 0 rgba(49, 199, 115, 1);
    -webkit-box-shadow: inset 0 0 6px 0 rgba(49, 199, 115, 1);
    -moz-box-shadow: inset 0 0 6px 0 rgba(49, 199, 115, 1);
    color: white;
    cursor: grab;
    height: 100%;
    justify-content: space-between;
    text-align: center;
    &:hover{
      background-color: rgb(17, 68, 40);
    }

    &:active {
      background-color: rgb(17, 68, 40);
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
    .scene-thumb{
      box-shadow: 0 1px 2px 0 rgb(255 255 255 / 60%);
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
}
.handle{
  height: 100%;
  width: 1px;
  color: red;
}
.marker-handle{
  height: 100%;
  width: 1px;
  position: absolute;
  &.left-handle{
    background-color: red;
    left: 5px;
  }
  &.right-handle{
    background-color: green;
    left: 4px;
  }
}
