.thumbnailImgWrapper {
  background-color: #d3d3d3;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto 10px;
}
.thumbnailActions {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.small-thumb {
  // width: 50px;
  height: 120px;
  object-fit: cover;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
}
