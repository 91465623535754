@import '../../../../assets/css/colors.scss';

.topBar {
  position: fixed;
  z-index: 2;
  display: flex;
  // height: 60px;
  margin-left: 0;
  padding: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // border-style: solid;
  // border-width: 0.5px;
  // border-color: #2b3441;
  // background-color: #050f1e;
  background-color: #06152b;
  //   border-bottom: 1px solid #2e3744;
}

.leftPart {
  display: flex;
}

.show-more-title {
  margin-top: 4px;
  font-size: 12px;
  color: #2176ff;
  cursor: pointer;
}

.optionsButton {
  font-size: 14px;
  padding: 5px 0;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

.optionsButton:hover {
  color: #bcbfca;
}

.top-bar-text {
  font-size: 14px;
  margin-bottom: 0;
  color: #fff;
}

.text-span {
  font-size: 14px;
  margin-left: 5px;
  color: #99b2c6;
}

.top-bar-upgrade-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 105px;
  height: 40px;
  margin-right: 10px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0 solid #000;
  border-radius: 20px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  text-decoration: none;
}

.top-bar-upgrade-button:hover {
  background-color: #678299;
}

.text-block {
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  text-decoration: none;
}

.top-bar-export-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 105px;
  height: 40px;
  margin-right: 0;
  margin-left: 0;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0 solid #000;
  border-radius: 20px;
  background-color: #3a36db;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  text-decoration: none;
}

.top-bar-export-button:hover {
  background-color: #678299;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    padding: 8px;
    height: 32px;
    box-shadow: none;
    svg {
      font-size: 20px;
      width: 20px;

      path {
        fill: white;
      }
    }
    i svg {
      font-size: 10px;
      width: 10px;
      height: 10px;
    }
  }
  button.saveBtn {
    path {
      fill: unset;
    }
  }

  p {
    color: #ffffff;
    margin-left: 10px;
    margin-right: 5px;
  }
}
.menu {
  width: 100%;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.dropdown {
  position: absolute;
  width: 200px;
  transform: translateX(10px);
  background-color: #678299;
  border: #678299;
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  transition: height 300ms ease;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 300ms;
  padding: 0.5rem;
}

.menu-item:hover {
  background-color: darken($color: #678299, $amount: 5%);
}

.optionsButtonDisabled {
  font-size: 14px;
  padding: 5px 10px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #ffffff66;
  cursor: not-allowed;
}

.btnSeq,
.btnSto {
  color: white;
  width: 40px;
  border-top: 2px solid #06152b;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  &:hover,
  &.active {
    border-top-color: #3a36db;
    // font-weight: 700;
  }
}

.hoverState {
  background-color: #2176ff;
  &:hover {
    // background-color: rgb(28, 25, 134);
    background-color: #2176ff;
    path {
      fill: #ffffff;
    }
  }
}

.linkText {
  padding: 5px 10px;
  height: 23px;
  font-size: 12px;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  svg {
    margin-right: 7px;
    font-size: 15px;
  }
  span {
    text-transform: uppercase;
    line-height: 1;
  }
}
