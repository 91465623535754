.timeline-container {
  width: 100%;
  .buttons-container {
    display: flex;
    .tab-buttons {
      height: 52px;
      &::-webkit-scrollbar {
        height: 0 !important;
        width: 6px;
        background: white;
      }
    }
    .timeline-actions {
      min-width: 370px;
      margin-left: auto;
      justify-content: flex-end;
      button {
        height: 22px;
        margin: 0 2px;
        padding: 0 15px;
        &:last-child {
          margin-right: 2px;
        }
        svg {
          font-size: 12px;
        }
      }
      .hoverState {
        &:hover {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
}
.text-red-500 {
  path {
    color: #ff5b5b;
  }
}
