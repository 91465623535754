.ws-header {
  height: 100px;
  box-shadow: 12px -6px 22px -11px rgb(0 0 0 / 75%);
  .ws-mp-link {
    background-color: #2176ff;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 0.5rem;
    height: min-content;
    margin-right: 5px;
    span {
      margin-left: 5px;
      line-height: 1;
    }
    &:hover {
      background-color: #4e36be;
    }
  }
}
