.logo {
  font-weight: 400;
  font-size: 40px;
  line-height: 150%;
  background: radial-gradient(50% 50% at 50% 50%, #3a36db 0%, #1a1f6a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Audiowide", sans-serif;
}

.sub-logo {
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 1.92em;
  background: radial-gradient(50% 50% at 50% 50%, #3a36db 0%, #1a1f6a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Audiowide", sans-serif;
}

.menu-wrraper {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 235px;
}

// .settings {
//   border-top: 0.5px solid rgba(0, 0, 0, 0.1);
// }
