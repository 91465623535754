#TimeLine {
  box-sizing: content-box;
  .no-element {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font: bold 2rem arial, sans-serif;
    background-color: #565656;
    color: transparent;
    // text-transform: uppercase;
    text-align: center;
    line-height: 8rem;
    text-shadow: 1px 1px 2px rgba(5, 15, 30, 0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    height: 160px;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 20px;
        margin-left: 7px;
        color: #8d949f;
        font-weight: 100;
      }
      svg {
        path {
          fill: #8d949f;
        }
      }
    }
    &:hover p {
      display: none;
    }
    &:hover:before {
      content: 'Click to add';
      font-size: 20px;
      margin-left: 7px;
      color: #8d949f;
      font-weight: 100;
    }
  }
  height: 100%;
  position: relative;
}
.rc-slider-with-marks {
  &.dataTime-slider {
    height: 30px;
    .rc-slider-rail,
    .rc-slider-step {
      height: 0;
      display: none;
    }
    .rc-slider-mark {
      top: -6px;
    }
  }
}

.TimeIndicator {
  .rc-slider-dot {
    display: none;
  }

  .rc-slider-with-marks {
    height: 42px;
    z-index: 1;

    .rc-slider-mark {
      .rc-slider-mark-text-active {
        color: white;
      }
    }
  }

  .rc-slider-rail,
  .rc-slider-track {
    height: 12px;
    top: -2px;
    border-radius: 0;
    background-color: transparent;
  }

  .rc-slider-rail {
    background-color: transparent;
    cursor: pointer;
  }
  .rc-slider-handle {
    top: -12px;
    width: 14px;
    height: 14px;
    margin-top: 10px;
    background-color: #f00;
    border: solid 2px #f00;
    border-radius: 0;
    transform: rotate(45deg) translateX(-50%) !important;
  }
}
.add-button {
  @apply w-8 ml-8 h-10 flex rounded-lg shadow-lg cursor-pointer shadow-black;
  background-color: rgba(5, 15, 30);
  position: relative;
  top: 40px;
  height: 200px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .add-title{
    height: 2rem;
    color: white;
    transform: rotate(90deg);
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
