.dropzone,
.dropzone-light {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  font-weight: 100;

  span {
    opacity: 0.4;
    margin-top: 15px;
  }
}
.fileList {
  display: flex;
  flex-wrap: nowrap;
  font-style: italic;
  white-space: nowrap;
  .fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.dzu-previewFileName {
  max-width: 60%;
  text-overflow: ellipsis;
}
.dzu-dropzone {
  height: fit-content;
  min-height: 0;
  padding: 10px;
  &:hover {
    .dropzone {
      span {
        opacity: 1;
      }
    }
  }
}
.dzu-dropzoneDisabled {
  opacity: 0.5;
  background-color: #80808070;
}
.preview-wrapper {
  width: 100%;
  .preview {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    width: 100%;
    align-items: center;

    .remove-file {
      cursor: pointer;
    }

    .progress {
      padding: 5px;
      width: 90%;
    }

    .preview-file-name {
      margin: 10px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 10%;
    }

    .status-icon {
      height: 12px;
      margin-left: 5px;

      svg {
        height: 12px;
      }
    }
  }
  .error {
    text-align: center;
    color: #fa9999;
  }
}

.dropzone-light {
  color: rgba(6, 21, 43, 0.3);
  &:hover {
    color: #3a36db;
  }
}
.dropzone {
  color: #ffffff;
  opacity: 0.3;
}
.dzu-inputLabel {
  position: relative;
}

.move-element-container {
  div.absolute {
    background-color: #3b557a;
    border: 3px dashed rgba(255, 255, 0, 0.7);
    p {
      text-transform: uppercase;
      font-size: 8px;
      color: #d5e7ff;
      opacity: 0.8;
      line-height: 1.6;
    }
    &:hover {
      p {
        opacity: 1;
      }
    }
  }
}
