.input-wrap {
  display: flex;
  padding: 5px;
  width: 100%;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(103, 130, 153, 0.54);
  font-size: 14px;
  color: white;
  input {
    background-color: rgba(103, 130, 153, 0.54);
  }
}
