.ws-user {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .profile-img {
    position: relative;
    input {
      display: flex;
      width: 120px;
      height: 120px;
      overflow: hidden;
      background: url('../../../assets/images/default avatar.jpeg') center center no-repeat;
      border-radius: 100%;
      background-size: 100px 100px;
      border: 3px solid #eeeeee;
      padding: 120px 0 0;
      cursor: pointer;
    }

    svg {
      fill: #3a36db;
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
}
.form-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-self: center;
  .buttons {
    button {
      width: 100%;
      animation: transformer 0.1s ease-in
    }
  }
  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    #Input {
      label {
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
      }
      input {
        background: #eeeeee;
        border-radius: 20px;
        overflow: hidden;
        padding-left: 20px;
        width: 400px;
        height: 45px;
        margin-top: 5px;
        user-select: none;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin: 2em;
    button {
      display: flex;
      //width: fit-content !important;
      user-select: none;
    }
  }
}

