.thumbnailImgWrapper {
  background-color: #d3d3d3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto 10px;
  img {
    opacity: 0;
    visibility: hidden;
  }
}
.thumbnailActions {
  position: absolute;
  bottom: 15px;
  right: 5px;
  button {
    padding: 10px 20px;
    height: 32px;
  }
}

.small-thumb {
  height: 120px;
  object-fit: cover;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
}
