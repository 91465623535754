.sidebar-content-wrapper {
  position: fixed;
  left: 0;
  width: 460px;
  height: 100vh;
  top: 0;
  margin-left: 50px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #2b3441;
  z-index: 2;
  -webkit-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  transform: translate(0px, 0);
  .button-primary {
    padding: 0 15px;
    height: 28px;
    gap: 0.5rem;
    font-size: 14px;

    span {
      font-weight: 300;
    }
  }
  .title {
    display: flex;
    height: 60px;
    padding-left: 10px;
    justify-content: flex-start;
    align-items: center;
    color: #99b2c6;
    font-size: 18px;
    text-align: left;
    justify-content: space-between;
    small {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-right: 25px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    height: 32px;
    margin-right: 25px;
    margin-bottom: 10px;
    margin-left: 0;
    padding: 0 5px;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: rgba(103, 130, 153, 0.54);
    text-indent: 10px !important;

    input {
      background-color: transparent;

      width: 100%;
      color: white;
      outline: none;
      padding-left: 5px;
    }
    input::placeholder {
      color: #fff;
      font-size: 14px;
    }
    svg {
      color: white;
      width: 18px;
      height: 18px;
    }

    .searchIcon {
      position: absolute;
      top: 16.5%;
      left: 10%;
    }
  }

  .close {
    position: absolute;
    top: 35%;
    right: -20px;
    z-index: 102;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0.5px 0.5px 0.5px 0;
    border-color: #2b3441;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #2b3441;
    cursor: pointer;
  }

  .filter-tabs {
    display: flex;
    overflow-x: auto;
    margin-bottom: 1rem;

    .tab {
      font-size: 14px;
      padding: 10px;
      background-color: transparent;
      color: #99b2c6;
      text-transform: uppercase;
      border-top: 2px solid transparent;
      cursor: pointer;

      &.tab-active {
        font-size: 14px;
        padding: 10px;
        background-color: transparent;
        text-transform: uppercase;
        border-top: 2px solid #3a36db;
        color: #fff;
      }
    }
  }
  .content-repo {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-right: 10px;
    .content-item {
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
      padding: 20px 10px;
      align-items: flex-start;
      border-style: solid;
      border-width: 0 0 0 0.5px;
      border-color: rgba(103, 130, 153, 0.54);
      transition: all 200ms ease;
      &:hover {
        background-color: rgba(103, 130, 153, 0.54);
      }
    }
  }
}
