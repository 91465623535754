#ElementList {
  height: fit-content;
  color: #99b2c6;
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  display: flex;
  align-items: flex-start;
  left: 0;
  background-color: transparent;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 5px;
  .element-parent {
    width: 100%;
    padding-left: 4px;
    line-height: 24px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    color: #ffffff80;
    display: flex;
    align-items: center;
    text-align: right;
    background-color: #06152b;
    border-bottom: 1px solid #000203;
    margin-bottom: 2px;
    border-radius: 2px;
    margin-top: 2px;
    text-transform: uppercase;

    padding-top: 2px;
    padding-bottom: 2px;
    &:hover {
      background: rgba(10, 35, 71, 0.2117647059);
      color: white;
    }
    .w-fit {
      button.button-small {
        padding: 0;
        height: auto;
        width: auto;
        svg {
          margin: 0;
        }
        span {
          &:empty {
            display: none;
          }
        }
      }
    }
    span {
      margin-left: 8px;
      margin-right: 5px;
      opacity: 0.7;
      font-weight: 100;
      margin-top: -2px;
    }
    svg {
      // margin-left: auto;
      margin-right: 5px;
      opacity: 0.7;
      font-weight: 100;
      margin-top: -2px;
    }
  }
  .element-parent-active {
    background: rgba(10, 35, 71, 0.4470588235) !important;
    border-bottom: 1px solid transparent !important;
    color: white;
  }
  .sequence-title {
    width: 100%;
    padding-left: 5px;
    margin-left: 0;
    line-height: 24px;
    cursor: pointer;
    overflow: visible;
    white-space: nowrap;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    position: relative;
    border: 1px solid transparent;
    // border: 1px solid #ffffff17;
    margin-bottom: 4px;
    border-radius: 2px;
    margin-top: 2px;
    .sync-button {
      width: 100px;
      padding: 2px 5px;
      margin-left: 10px;
      &.synced {
        background-color: #1ad598;
      }
      &.free-set {
        background-color: #fa5656;
      }
    }
    &.sub-item {
      width: 100%;
      &.layer {
        border: none;
        background-color: transparent;
        opacity: 0.8;
        // background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #050f1e 10px),
        //   repeating-linear-gradient(#06152b55, #06152b);
      }
    }
    .icon-title {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: flex-start;
      svg {
        opacity: 0.5;
        font-size: 17px;
      }
    }
    &:hover {
      border: 1px solid #2b3441;
      // background-color: rgba(43, 52, 65, 0.3);
      svg {
        opacity: 1;
      }
    }
    .delete-element {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
    }

    &.map {
      width: 100%;
      margin-left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 5px;
      text-align: right;
      cursor: pointer;
    }
    span {
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      margin-bottom: 1px;
      text-align: left;
    }
    div {
      button {
        border: none;
        box-shadow: none !important;
        padding: 0;
        background-color: transparent !important;
        height: unset;
      }
    }
  }
  .sequence-title.active {
    // background-color: #2b3543;

    outline: none;
    border: 1px solid rgb(80 157 80);
    svg {
      opacity: 1;
    }
  }
  .active {
    // width: calc(100% - 20px);
    background-color: transparent;

    .sequence-title {
      // background-color: #2b3543;
      // background-color: #050f1e;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 1px solid #474e59;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 5px;
      text-align: right;
      svg {
        opacity: 1;
      }
    }
  }
  .group-tabs {
    width: 100%;
    height: 24.5px;
    border-bottom: 1px solid #2b344170;
    position: relative;
    top: 1rem;
    padding-bottom: 0.5rem;
    button {
      margin: 0 0.2rem;
      width: 24.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background-color: #0a2347;
      border: 1px solid #212e41;
    }
    .create-group-button {
      width: auto;
      background-color: transparent;
      border: 1px solid transparent;
    }
    .active-group {
      background-color: #061327;
      border: 1px solid #2e3e55;
    }
  }
  .sequence-title,
  .timeline-map-wrapper {
    width: calc(100% - 1px);
  }
  .group-list {
    // overflow: hidden;
    .element-parent {
      width: 100%;
      padding-top: 2px;
      padding-bottom: 2px;
      justify-content: space-between;
      svg {
        margin-left: 4px;
      }
    }
    .drop-target {
      position: relative;
      outline: 1px solid #2176ff;
    }
    .dragging-source {
      background-color: #2176ff5f;
    }
    .sequence-title.map,
    .sub-item {
      padding-left: 0;
    }
  }
}
.sub-item {
  display: flex;
  align-items: center;
  height: 0;
  text-transform: capitalize;
  text-align: right;
  padding-right: 5px;
  font-size: 0;
  overflow: hidden;
  transition: all 0.2s;
  padding: 0 5px 0 19px;
  justify-content: space-between;
  .button {
    width: 20px;
    padding: 0;
    background-color: rgba(103, 130, 153, 0.2);
    height: 20px;
    margin: 0;
    &:disabled {
      background-color: rgba(0, 0, 0, 0.1);
    }
    svg {
      font-size: 6px !important;
    }
  }
  &.open {
    height: 30px;
    font-size: 0.8rem;
  }
  &.layer {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: none;
    align-self: end;
  }
}
.break-line {
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  width: 1px;
  height: 100%;
  margin-top: 10px;
  margin-left: 250px;
}
.icon-title {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    opacity: 0.5;
  }
}
.choice-container {
  position: absolute;
  top: 25px;
  bottom: 0;
  padding: 0 10px 10px 10px;
  border: 1px solid #43536b;
  border-radius: 8px;
  background-color: #0f274b;
  width: 100%;
  height: 100px;
  left: 100%;
  z-index: 9;
  color: white;
  .choice-wrapper {
    p {
      margin: 5px 0;
      text-transform: capitalize;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .header {
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #43536b;
    margin-bottom: 5px;
  }
  .choice {
    font-size: 0.8rem;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .cancel {
    text-transform: lowercase;
    cursor: pointer;
    color: #fa5656;
    font-size: 0.7rem;
  }
}
.timeline-map-wrapper {
  cursor: default;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &:hover {
    border: 1px solid #2b3441;
  }
  .w-100 {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    &:empty {
      display: none;
    }
  }
  .map-sub-element {
    max-width: 130px !important;
    display: flex !important;
    align-items: center !important;
    color: white;
  }
  .sequence-title.map {
    margin-bottom: 1px !important;
    border-radius: 4px !important;
    border: none !important;
  }
  .sequence-title.sub-item.layer {
    margin-left: 5px;
    cursor: default !important;
    .toggle-bg {
      height: 1.3rem;
    }
    .toggle-bg:after {
      top: 0.15rem;
      left: 0.6rem;
      height: 1rem;
      width: 1rem;
    }
    .toggle-bg.border-gray-200:after {
      left: 0.2rem;
    }
  }
}
.timeline-map-wrapper.active {
  border: 1px solid rgb(80, 157, 80) !important;
  outline: none;
}
.sub-wrapper {
  width: calc(100% - 25px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-size: calc(100% - 25px) 100%;
  background-clip: content-box;
  position: relative;
}

button.button.button-medium.button-link.default.surface-temp-icon {
  margin-right: 0.4rem !important;
  svg {
    font-size: 24px !important;
    color: white;
  }
}
