.menu-item {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  padding: 10px 14px 10px 0;
  align-items: center;
  // transition: all 0.2s ease-in;
  svg {
    margin-left: 16px;
    margin-right: 20px;
    width: 18px;
    height: 18px;
  }
  &:hover {
    svg {
      fill: #3d39db;
    }
  }
}

.menu-wrraper {
  margin: 10px;
  // padding: 20px 24px;
  border-radius: 12px;
  color: #06152b;
  cursor: pointer;
  margin-bottom: 10px;

  img {
    margin-left: 16px;
    margin-right: 20px;
  }

  // transition: all 0.2s ease-in;
  &:hover {
    background: rgb(244, 248, 255);
    color: #3a36db;
    img {
      filter: grayscale(0%) !important;
      opacity: 1 !important;
    }
  }
}

.active-link {
  background: rgb(244, 248, 255);
  color: #3a36db;
  img {
    filter: grayscale(0%) !important;
    opacity: 1 !important;
  }
  svg {
    fill: #3d39db;
  }
}

.img {
  width: 18px;
  height: 18px;
  filter: grayscale(100%);
  opacity: 0.4;
}
