@import "../../assets/css/colors.scss";
.item-card-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 43px;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-top: 1rem;
  overflow: hidden;
  .item-card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 -1px 10px 0 hsl(213deg 8% 78% / 25%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease;
    width: 250px;
    .image-container {
      img {
        object-fit: cover;
        height: 220px;
      }
    }
    .item-data {
      background-color: white;
      padding: 10px;
      p {
        text-transform: capitalize;
        color: rgba($bigFont, 0.75);
        &:hover {
          text-decoration: underline;
        }
      }
      .item-links {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        span {
          color: rgba($bigFont, 0.3);
        }
      }
      .item-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: $secondaryPurple;
          font-weight: 700;
          font-size: 25px;
        }
        .button-link {
          padding: 0;
          margin: 0;
          path {
            fill: #333;
          }
        }
      }
    }
    &:hover {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
      .image-container {
        position: relative;
        .overlay {
          transition: all 1s;
          position: absolute;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.75) 0%,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0) 100%
          );
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }
      }
    }
  }
}
