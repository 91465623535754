@import "../../assets/css/colors.scss";

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 32px;
    @media(max-width: 500px) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-title {
        padding-bottom: 20px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &-item {
        display: flex;
        gap: 4px;
        margin-left: 0 !important;
        line-height: 14px;
        font-size: 12px;
        font-weight: 300;

        span {
            text-transform: uppercase;
        }

        span:hover {
            text-decoration: underline;
            text-decoration-color: $primaryBlue;
        }
    }

    .ant-checkbox-inner {
        border-radius: 0;
        border-color: black;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-radius: 0;
            border-color: $primaryBlue;
        }
    }

}