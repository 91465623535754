.available-weather {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.available-weather-form-wrapper {
    width: 100%;
    flex-direction: column;
}

.available-weather-types {
    width: 100%;
}

.ws-icon-preview-container {
    margin-top: 0;
}