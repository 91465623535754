.circle-input-container {
  position: relative;
  margin-left: 20px;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid black;
  background-color: white;
  cursor: pointer;

  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5px;
    height: 12.5px;
    background-color: red;
    transform-origin: top;
    transform: translate(-50%, -100%) rotate(0deg);
  }
}
