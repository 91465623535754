.lpbackground:nth-child(even) {
  .images {
    flex-direction: row-reverse;
    justify-content: center;
  }

  svg.odd {
    display: none;
  }
}

.lpbackground:nth-child(odd) {
    .images {
      justify-content: center;
    }
  
  svg.even {
    display: none;
  }
}
.lpbackground {
  .images {
    img {
      height: auto;
      top: 0px;
      position: relative;
      transition: all 1s;
      cursor: pointer;
      &:hover {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }

  .counts {
    font-variant-numeric: tabular-nums;
    span {
      font-size: 36px;
    font-weight: 500;
    opacity: 0.7;
    }
    .plus {
      margin-left: 0.5rem;
    }
  }

  .trailsText {
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.7490196078);
    font-size: 3em;
    font-weight: 800;
    letter-spacing: -0.05em;
    will-change: transform, opacity;
    overflow: hidden;
    span {
      text-transform: uppercase;
    }
  }
  
  .trailsText > div {
    padding-right: 0.05em;
    overflow: hidden;
  }
  
  .containerTrail {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    h1 {
      text-transform: uppercase;
      font-weight: 800;
      line-height: 1;
    }
  }


  .wrapperExplore {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    width: 75%;
    .trailsText {
      color: white;
      font-weight: 100;
      font-size: 20px;
      line-height: 28px;
      height: 28px;
    }
  }
  
  .cta {
      display: flex;
      padding: 10px 40px;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      color: white;
      background: #2176ff;
      transition: 1s;
      box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.172);
      transform: skewX(-10deg);
      border-radius: 2px;
      cursor: pointer;
      svg {
        width: 30px;
        height: 20px;
      }
  }
  
  .cta:focus {
     outline: none; 
  }
  
  .cta:hover {
      transition: 0.5s;
      box-shadow: 10px 10px 0 #FBC638;
  }
  
  .cta span:nth-child(2) {
      transition: 0.5s;
      margin-right: 0px;
  }
  
  .cta:hover  span:nth-child(2) {
      transition: 0.5s;
      margin-right: 45px;
  }
  
    span {
      transform: skewX(10deg) 
    }
  
    span:nth-child(2) {
      width: 20px;
      margin-left: 30px;
      position: relative;
      top: 12%;
    }
    
  /**************SVG****************/
  
  path.one {
      transition: 0.4s;
      transform: translateX(-60%);
  }
  
  path.two {
      transition: 0.5s;
      transform: translateX(-30%);
  }
  
  .cta:hover path.three {
      animation: color_anim 1s infinite 0.2s;
  }
  
  .cta:hover path.one {
      transform: translateX(0%);
      animation: color_anim 1s infinite 0.6s;
  }
  
  .cta:hover path.two {
      transform: translateX(0%);
      animation: color_anim 1s infinite 0.4s;
  }
  
  /* SVG animations */
  
  @keyframes color_anim {
      0% {
          fill: white;
      }
      50% {
          fill: #FBC638;
      }
      100% {
          fill: white;
      }
  }
}


.floating { 
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.rotating {
  animation: rotation 10s infinite linear;
}

.fliping {
  animation: fliping 10s infinite linear;
}

@keyframes fliping {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}