@import "../../assets/css/colors.scss";

.sorting-wrapper {
  display: flex;
  align-self: flex-end;

  button:first-child{
    margin-left: 0;
  }
  button {
    padding: 5px;
    height: 32px;
    margin: 0 0 0 20px;
    opacity: 0.7;
    margin-right: 11px;
    @media (max-width: 500px) {
      padding: 0;
    }
 

    span {
      display: flex;
      align-items: center;
      font-weight: 300;
      line-height: 15px;
      font-size: 12px;
      white-space: nowrap;
      position: relative;
      text-transform: uppercase;
      color: #2F2F2F;

      svg {
        font-size: 15px;
        position: absolute;
        right: -19px;
        top: -15px
      }
    }

    span:hover {
      text-decoration: underline;
      text-decoration-color: $primaryBlue;
    }
  }

  button:hover {
    background-color: transparent !important;
  }

  button.selected {
    opacity: 1;

    span {
      font-weight: 600;
    }
  }
}