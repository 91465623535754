 @import "../../assets/css/colors.scss";

 .ant-select-title {
    padding-bottom: 20px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
 }

 .ant-select {
    width: 100%;

    &-selector {
       border-radius: 0px !important;
    }

    [type='search']:focus {
       box-shadow: none;
    }

    &-big{
      width: 150px;
      border-radius: 4px;
      .ant-select-selector{
       min-height: 100%;  
      }  
    }
 }