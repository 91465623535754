.voice-over-recorder {
  padding: 5px;
  display: flex;
  justify-content: center;
}
.audio-recorder-class {
  height: 29px;
  width: 29px;
  padding: 7px 12px!important;
}
