.keyframe {
  pointer-events: initial;
  position: absolute;
  cursor: pointer;
  top: 10px;
  transform: translate(-3px, -3px);

  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
}

.ttContainer {
  position: absolute;
  z-index: 999;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  transform: translateX(-50%);
  display: grid;
  opacity: 1;
  grid-template-columns: auto auto;
  .ttElement {
    text-align: center;
  }
  .ttElementLabel {
    text-align: right;
  }
}
.bar {
  top: 10px;
  height: 1px;
  position: absolute;
  background-color: #1ad598;
  z-index: 3;
}
