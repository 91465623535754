.wrapper {
  border-radius: 0 20px 20px 20px;
  margin-top: -20px;
  padding: 10px;
  min-height: 100vh;
}
.projects {
  display: flex;
  max-height: 400px;
  background-color: #f6f6f6aa;

  // overflow-y: scroll;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 500px;
  column-gap: 0;
  row-gap: 30px;
  margin-left: 0;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tab-search {
  margin-left: auto;
  align-self: center;
}

.input-wrap {
  background: #eeeeee;
  border-radius: 20px;
  overflow: hidden;
  padding-left: 20px;
  margin-left: auto;
  max-width: 400px;
  margin-top: -40px;
}

.search-input {
  outline: none;
  height: 32px;
  background: #eeeeee;
  width: 500px;
  margin-left: 20px;
  margin-bottom: 3px;
}

.search-input::placeholder {
  color: rgba(6, 21, 43, 0.8) !important;
  opacity: 1;
  font-size: 18px;
}

.item {
  position: relative;
  display: flex;
  width: 250px;
  //   height: 328px;
  margin: 20px;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 -1px 10px 0 rgb(195 199 204 / 25%);
  transition: all 200ms ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 -1px 20px 10px rgba(195, 199, 204, 0.6);
    .dot-menu {
      visibility: visible;
    }
  }
}

.item-content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
}

.item-title {
  font-size: 18px;
  font-weight: 700;
  color: rgba(6, 21, 43, 0.7);
}

.item-dates {
  display: flex;
  margin-bottom: 0;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.dates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-small {
  padding-top: 4px;
  color: rgba(6, 21, 43, 0.7);
  font-size: 12px;
}

.date-created {
  color: rgba(6, 21, 43, 0.7);
  font-size: 12px;
  font-weight: 600;
}

.dot-menu {
  visibility: hidden;
  position: absolute;
  left: auto;
  top: 2%;
  right: 0;
  bottom: auto;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 0 5px 10px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}
.dot-menu-price {
  visibility: visible;
  position: absolute;
  top: 2%;
  left: 2%;
  bottom: auto;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
  padding: 0 5px 10px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}

.dot-menu-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: 2px;
  margin-bottom: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 200ms ease;
  color: #06152b;
  text-decoration: none;
  background-color: #fff;

  &:hover {
    background-color: #f4f8ff;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.dot-menu-link-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  justify-content: space-around;
  align-items: center;
  border-radius: 50px;
  transition: all 200ms ease;
  color: #06152b;
  text-decoration: none;
  background-color: #3a36db;
  //   color: #e56426;

  div {
    color: #ffffff !important;
    // color: #e56426 !important;
    font-size: 12px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.highlight-text {
  background-color: #ffdc39;
  font-size: 18px;
  font-weight: 700;
  color: rgba(6, 21, 43, 0.7);
}

.dots-wrapper {
  display: flex;
  position: absolute;
  align-items: flex-end;
  bottom: 9px;
  margin: auto;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 1;
  // left: 50%;
}

.pagination-item {
  display: flex;
  width: 10px;
  height: 3px;
  background-color: #f6f6f6ee;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  //   transition: all 50ms ease;
  color: #06152b;
  z-index: 999;
  font-size: 16px;
  transition: height 0.3s, width 0.3s;
  .active {
    background-color: #3a36db;
    color: #fff !important;
  }
}

.pagination-item-active {
  display: flex;
  width: 15px;
  height: 5px;
  border-color: rgb(0, 84, 255);
  background: rgb(0, 84, 255);
  border-width: 2px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  //   transition: all 50ms ease;
  color: #06152b;
  z-index: 3;
  font-size: 16px;
}
.slider {
  display: flex;
  flex-wrap: nowrap;
  user-select: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .img {
    display: flex;
    justify-content: center;
    min-width: 100%;
    height: 210px;
    min-height: 210px;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
    transition-timing-function: ease-out;
    transition: all 0.5s ease-in-out;
    transform: translateX(0);
    img {
      object-fit: cover;
    }
    //position: relative;
  }

  .previous {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 0;
    cursor: pointer;
    z-index: 2;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 30px;
      height: 30px;
    }
    &:empty {
      display: none;
    }
  }

  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 0;
    cursor: pointer;
    z-index: 2;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 30px;
      height: 30px;
    }
    &:empty {
      display: none;
    }
  }
}
