@import "../../assets/css/colors.scss";
.my-profile {
  .profile-heading {
    background-color: rgb(246, 246, 246);
    padding: 10px;
    max-width: 100%;
    margin: 0 auto;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-bottom: 80px;

    .content {
      display: flex;
      width: 80%;
      align-self: center;
      padding: 10px;
      margin-top: 80px;


      .user {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../../../../assets/images/default\ avatar.jpeg") center
          center no-repeat;
        width: 120px;
        height: 120px;
        position: relative;
        cursor: pointer;
        border-radius: 100%;
        background-size: contain;
        
      }

      .title {
        color: rgba($bigFont, 1);
        font-size: 32px;
        font-weight: 600;
      }

      .description {
        margin-top: 20px;
      }
    }
  }
}
