.ant-layout-footer {
    padding: 0;
    color: unset;
    font-size: unset;
    background: unset;
}

.ant-layout-header {
    background-color: white;
    height: unset;
    line-height: unset;
    padding: 30px 2rem 30px 20px !important;
    margin: 0 auto;
    max-width: unset !important;
    @media(max-width: 500px) {
        padding: 0.5rem 0.5rem 0.5rem 10px !important;
    }
}

.ant-layout {
    display: unset;
    min-height: unset;
    box-sizing: unset;
    background: unset;
}