.create-card {
  background: rgba(217, 217, 217, 0.12);
  border: 0.5px solid rgba(163, 163, 163, 0.5);
  border-radius: 8px;
  padding: 30px 20px;
  flex-direction: column;
  display: flex;
  .title {
    color: #445060;
    font-weight: 700;
    font-size: 22px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    color: #445060;
    margin: 15px 0 35px;
  }
  button {
    width: 100%;
  }
}
