.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f6f6f666;

  svg {
    width: 100px;
    height: 100px;
    stroke: rgba(217, 217, 217, 0.95);
  }

  span {
    font-size: 24px;
    color: rgba(217, 217, 217, 0.95);
  }
}

.leftSidebarLinkTitle {
  display: flex;
  width: 40px;
  height: 40px;
  margin: 20px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0.5px;
  border-color: #3a36db;
  border-radius: 20px;
  background-color: transparent;
  // transition: all 200ms ease;
  color: #fff;
  cursor: pointer;
  svg {
    color: white;
  }
  &.opened {
    background-color: rgb(43, 52, 65);
  }

  svg {
    fill: #3a36db;
    stroke: #3a36db;
  }
}
