.ant-drawer-content.cron-drawer {
  background-color: #1f2a40;
  .sch-list {
    padding: 0;
  }
  .ant-btn-primary {
    background-color: #2176ff;
  }
  .add-cron-button {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .schedule-card {
    border: 1px solid #3a4457;
    box-shadow: rgba(0, 0, 0,0.1) 1px 1px 5px 1px;
    h3 {
      color: white;
      text-transform: uppercase;
      font-size: 19px;
    }
    color: white;
    .schedule-and-actions {
      flex-direction: column;
      .schedule {
        opacity: 1;
        span {
          margin-bottom: 1rem;
          b {
            font-weight: 400;
            color: white;
            margin-right: 7px;
            display: block;
            opacity: 1;
          }
          em {
            opacity: 0.7;
          }
        }

      }
      .schedule-actions {
        margin-top: 1rem;
      }
    }
  }
}
.studio-cron-modal {
  color: white;
  .ant-btn-primary {
    background-color: #2176ff;
  }
  .ant-modal-header, .ant-picker, .ant-input-number-handler-wrap, .ant-input-number, .ant-input, input, .cron_builder_bordering select {
    background-color: transparent;
    border-color: #6B7280;
  }
  .ant-modal-content {
    background-color: #1f2a40;
  }
  .cron_builder {
    border-color: #6B7280!important;
    .well {
      border-color: #6B7280;
    }
    .nav-tabs {
      color: white;
      li {
        button {
          color: white;
        }
      }
      .nav-item.show .nav-link, 
      .nav-link.active,
      .nav-link:hover {
        background-color: #37486a;
        color: white;
      }
    }

    .cron_builder_bordering, .cron-builder-bg, .tab-pane.active {
      background-color: #1f2a40;
      color: white;
    }
  }
} 
.dark-modal {
  .ant-modal-content {
    background-color: #1f2a40;
    .ant-modal-confirm-content, .ant-modal-confirm-title, .ant-btn-default {
      color: white;
    }
    .ant-btn-dangerous {
      color: #ff4d4f;
    }
  }
}