.ws-layout {
  //background-color: rgb(246, 246, 246);
  padding: 2em;
  overflow-y: auto;
  height: calc(100% - 100px);
  position: relative;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  .ws-layout-inner {
    width: 100%;
    margin: 0 auto;
  }
}
.ws-grid-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ws-grid-layout {
    width: -webkit-fill-available;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    row-gap: 4rem;
    column-gap: auto;
    margin-bottom: 5rem;
    margin-top: 1em;
    @media(max-width: 1024px) {
      grid-template-columns: repeat(2, 44%);
      column-gap: 2rem;
    }
    @media(min-width: 1024px) and (max-width: 1280px) {
      grid-template-columns: repeat(3, 29.33%);
    }
  }
  .ws-no-data {
    position: relative;
    height: fit-content;
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
.ws-input {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  height: fit-content;
  right: 0;
  padding: 0 10px 0 10px;
  border-radius: 0.5rem;
  background-color: white;
  margin-left: 10px;
  svg {
    width: 17px;
    height: 17px;
  }
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  input {
    width: 100%;
    height: 30px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: rgba(0, 0, 0, 0.4);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.4);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
    &:focus-visible {
      outline: none;
      border: none;
    }
  }
}


.ws-input-project {
  @media (max-width: 1024px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.ws-grid-loader {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ws-tab-button {
  text-decoration: underline;
  color: #3a36db;
}
.ws-tab-button-inactive {
  color: initial;
  text-decoration: unset;
}
.ws-button,
.ws-button-inactive {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  height: min-content;
  margin-right: 20px;
  &:hover {
    background-color: #1a1f6a;
    color: white;
  }
}
.ws-button {
  padding: 5px 15px !important;
  background-color: #3a36db;
  color: white;
  height: 32px;
  @media(min-width: 1536px) {
    padding: 5px 13px !important;
    height: 32px;
  }
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}
.ws-button-inactive {
  background: white;
  box-shadow: 2px 2px 20px -14px rgb(0 0 0 / 75%);
  color: #06152b;
}
