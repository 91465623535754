.properties__tabs_wrapper {
  .prop-wrapper {
    padding: 1rem 1rem 1rem 0;
    position: relative;
    h4 {
      margin: 1rem 0;
      padding-left: 141px;
      color: white;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .accordion-body {
    border-bottom: 1px solid #ffffff19;
    margin-bottom: 1rem;
    flex-direction: column;
    .grid.grid-cols-2 {
      gap: 15px;
    }
    b {
      color: rgb(255, 255, 255);
      font-weight: 300;
      margin-right: 7px;
    }
    .cursor-pointer {
      span {
        display: none;
      }
    }
    .delete-layer {
      margin-bottom: 1rem;
    }
  }
  //   .toggle-bg {
  //     &::after {
  //       top: 0.3rem;
  //     }
  //   }
  .layers-wrapper {
    padding-bottom: 0;
  }
  .layer-header {
    border-radius: 2px;
    &:hover {
      background: #0a234736;
    }
    button {
      margin-left: 0;
      svg {
        opacity: 0.5 !important;
      }
      span {
        padding: 0 5px 0 0;
        color: white;
        margin-left: 5px;
      }
    }
  }
  .geo-poster-prop {
    background-color: #06152b;
    border-bottom: 1px solid #00283d;
  }
  .layer-title {
    display: flex;
    align-items: center;
    //min-width: 200px;
  }
  .subheader.layer-disabled {
    pointer-events: none;
    cursor: default;
    svg {
      opacity: 0.2;
    }
    button {
      cursor: pointer;
      pointer-events: all;
    }
  }
  .layer-header-active {
    background: #0a234772 !important;
    border-bottom: 1px solid transparent !important;
  }
  .subheader,
  .subheader-1 {
    font-weight: 300;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(0 0 0);
    text-transform: uppercase;
    padding: 3px 10px 3px 10px;
    margin-top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .capitalize {
    padding-left: 10px;
  }
  .grid-item-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .grid-item,
  .input-wrap {
    height: 25px;
    padding: 0;
  }

  .grid-item {
    background-color: #060b12;
    border: 1px solid #2b3441;
    border-radius: 2px;
    padding: 0 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    button {
      width: auto;
    }
    input {
      height: 23px !important;
      padding: 0;
    }
  }

  .grid-item-slider {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }

  button {
    height: 20px;
    text-transform: uppercase;
    border-radius: 4px;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .grid-button {
    width: 100%;
    padding-left: 136px;
    button {
      margin: 1rem 1rem 0 0;
      &:hover {
        opacity: 0.8;
      }
      &:focus {
        outline: none;
      }
      svg {
        margin-right: 8px;
        font-size: 18px;
      }
      span {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  .grid.prop-grid-4 {
    grid-template-columns: 130px 1fr 130px 1fr;
    // grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 0.5rem;
    row-gap: 0.5rem;
    margin-right: 0;
    align-items: center;
    width: 100%;
    .grid-item {
      label {
        color: #ffffffd6;
      }
      .lottie {
        height: 100%;
      }
      &:empty {
        height: 0;
      }
    }
    .grid-item:nth-child(odd) {
      max-width: 170px;
    }
    .grid-item:nth-child(even) {
      //justify-content: flex-end;
    }
  }

  input {
    border: none;
    background: transparent;
    &:hover {
      border-color: #273c58 !important;
    }
    &:focus {
      border-color: #2176ff !important;
      box-shadow: none;
    }
  }
  select {
    border: none;
    background-color: transparent;
    border-radius: 0;
    text-align: left;
    padding: 0;
    color: #ffffff;
    height: 23px;
    min-height: unset;
    font-size: 14px;
    line-height: 1;
    option {
      background: #3a4d61;
    }
    &:hover {
      border-color: #273c58;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .no-border-bg {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: visible;
  }

  //   .prop-switcher {
  //     display: flex;
  //     border: none;
  //     background-color: transparent;
  //     padding: 0;
  //     button {
  //       width: 49px;
  //       margin-right: -13px;
  //       .border-gray-200.bg-gray-200 {
  //         background-color: #060b12;
  //         border: 1px solid #2b3441;
  //       }
  //     }
  //   }
  .toggle-bg.border-gray-200 {
    background-color: #060b12;
    border: 1px solid #2b3441;
  }
  .rc-slider {
    .rc-slider-track {
      background-color: #6c2bd9;
    }
    & + input {
      width: 70px;
      min-width: 70px;
      margin-left: 1rem;

      background-color: #060b12;
      border: 1px solid #2b3441;
      border-radius: 2px;
      padding: 0 5px;
    }
  }
  .rc-slider-disabled {
    background-color: transparent;
    opacity: 0.2;
  }

  .tb-box-wrapper {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: relative;
    width: 100%;
    div {
      position: absolute;
    }
    div.tb-box {
      position: static;
    }
    input {
      max-width: 30px;
      min-width: unset;
      text-align: center;
    }
    .padding-top-box {
      top: 3px;
      border: 1px solid #2b3441;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }
    .padding-bottom-box {
      bottom: 3px;
      border: 1px solid #2b3441;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }
    .padding-right-box {
      right: 3px;
      border: 1px solid #2b3441;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }
    .padding-left-box {
      left: 3px;
      border: 1px solid #2b3441;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
    }
  }
  .font-alignment {
    width: 100%;
    button {
      background-color: #060b12;
      border-color: #2b3441;
      height: 25px;
      width: 100%;
      span {
        align-items: center;
        justify-content: center;
        svg {
          color: white;
        }
      }
      &:focus {
        outline: 1px solid #1a56db;
      }
    }
  }
}

// @media only screen and (max-width: 1380px) {
//   .properties__tabs_wrapper {
//     .grid.prop-grid-4 {
//       grid-template-columns: repeat(2, minmax(0, 1fr));
//     }
//   }
// }

.BaseMapLayers__checkbox-wrap {
  label.container {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-weight: 600;
  }
}
