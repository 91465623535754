.thumbnail {
  width: 212px;
  height: 116px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
  &.selected {
    border: 2px solid #6445f1;
    opacity: 1;
  }
}
