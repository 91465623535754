.user-actions-modal{
#Input input {
        border: 1px solid #ddd;
        border-radius: 4px;
        outline: none;
        padding: 0px 5px;
        min-height: 28px;
    }

    #Input label {
        font-size: 16px;
        margin-top: 0;
        opacity: 0.6;
    }

    .ant-input {
        border-radius: 6px;
        border-color: #e5e7eb;

        :hover {
            border-color: #4096ff !important;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #e5e7eb;
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #e5e7eb;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #e5e7eb;
        }
    }

    .ant-switch{
        display: block;
        margin-top: 8px;
    }
    .ant-switch-inner{
        background: rgba(0, 0, 0, 0.20);
    }
}