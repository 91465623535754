.iconWrapper {
  width: 150px;
  height: 150px;
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  //  background-color: darken($color: #f6f6f6, $amount: 2%);

  svg {
    width: 100px;
    height: 100px;
    fill: #fa5656;
    // fill: darken($color: #f6f6f6, $amount: 5%);
    margin-left: 10px;
    // stroke: #f6f6f666;
  }
}

.title {
  display: flex;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
  color: #fa5656;
  margin: 10px 0;
}

.description {
  font-size: 16px;
  color: #fa5656;
}
