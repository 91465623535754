@import '../../../assets/css/colors.scss';

.ws-project-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 400px);
  margin-bottom: 10px;
  margin-top: 7px;

  .ws-tabs {
    display: flex;
  }
}

.ws-fixed-header {
  display: flex;
  align-items: center;
  z-index: 2;
  background: white;
  padding: 5px;

  position: sticky;
  top: -30px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .ws-tabs {
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 10px 20px;
    cursor: pointer;
  }

  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
  }

  .ws-button {
    white-space: nowrap;
    margin: 0;
  }
  .css-1lpvrj-control {
    min-height: unset;
    height: 32px;
  }
  .css-1xc3v61-indicatorContainer {
    padding: 4px 8px;
  }
}

.in-development {
  height: unset;
  position: absolute;
  width: unset;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.ws-temp-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-right: 20px;
  font-size: 2rem;
  font-weight: 600;

  h1 {
    text-transform: uppercase;
    font-size: 1.7rem;
    @media (max-width: 1280px) {
      font-size: 1.5rem;
    }

    small {
      em {
        font-weight: 100;
        text-transform: initial;
        font-size: 20px;
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
}

.project-setup-content {
  h2 {
    display: flex;
    flex-direction: column;

    small {
      line-height: 1;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      opacity: 0.5;
    }
  }
}

.ws-calendar-input {
  position: relative;

  .calendar-icon {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

.thumb-wrapper {
  flex-direction: column;

  .confirm-thumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 2em;
  }

  .thumb-slider {
    width: 100%;
    margin: 0 auto;
  }

  .aside {
    padding: 0 2em;
    width: 100%;
    max-width: 1200px;
    margin: 2em auto;

    .ws-tag-area {
      input {
        margin-top: 0;
      }
    }

    .ws-tags-container {
      margin-bottom: 1em;

      &:empty {
        display: none;
      }
    }

    button {
      margin: 0;
      width: 100%;
    }

    .button.button-link {
      text-decoration: underline;
      color: #fa5656;
      padding: 0;
      width: auto;
      margin: 0 auto;
    }

    .grid.grid-cols-4 {
      gap: 1em;

      .uploaded-image {
        margin: 1em 0;
        width: 100%;
      }
    }
  }

  .choose-file {
    input {
      color: #929292a3;
    }
  }

  .ws-central {
    & + h2 {
      margin-top: 1.5em;
      margin-bottom: 1em;
    }
  }
}

.ws-icon-codes-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 53px;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  .ws-icon-code {
    width: fit-content;
    border-radius: 5px;
    padding: 5px 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    // flex: 0 0 6rem;
    text-align: center;

    &.ws-active-type {
      background: $primaryBlue;
      border-color: $primaryBlue;
      color: white;
    }
  }
}

.ws-icon-form {
  margin: 30px 0 30px 0;
  padding: 20px;
  border: 1px solid #1a56db;
  border-radius: 10px;

  .ws-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ws-icon-preview-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  grid-template-rows: auto auto 1fr 1fr 1fr auto auto;

  .ws-icon-preview {
    border-radius: 3px;
    flex: 0 1 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // -webkit-box-shadow: 10px 10px 4px -4px rgba(222, 222, 222, 0.54);
    // -moz-box-shadow: 10px 10px 4px -4px rgba(222, 222, 222, 0.54);
    // box-shadow: 10px 10px 4px -4px rgba(222, 222, 222, 0.54);
    border: 1px solid #33333324;

    .ws-icon-title {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 120px;
    }

    span {
      margin-top: auto;
      font-style: italic;
      font-size: 0.8rem;
    }
  }
}

.ws-fixed-header-add-project {
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    min-width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .thumb-wrapper {
    flex-direction: column;

    .thumb-slider,
    .aside {
      width: 100%;
    }

    .confirm-thumb {
      margin-top: 2em;
    }
  }
}

// custom.css
.CircularProgressbar {
  width: 150px !important;
}

.wrapper-check {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkmark__circle-check {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark-check {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0% auto;
  box-shadow: inset 0px 0px 0px 10px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #7ac142;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #7ac142;
  }
}
