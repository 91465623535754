@import "../../assets/css/colors.scss";

.ant-card-item {
  padding: 0 !important;
  background-color: inherit;
  border: none;

  .ant-card-cover {
    img {
      min-height: 330px !important;
      height: 330px !important;
      object-fit: cover !important;
      border-radius: 16px !important;
    }
  }

  .ant-card-body {
    padding: 12px 0 12px 0 !important;

    &-title {
      max-width: 100%;
      line-height: 22px;
      font-size: 18px;
      display: inline-flex;
      flex-flow: row nowrap;

      .name {
        color: #444;
        flex: 1;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
      }

      .type {
        color: #A8A8A8;
        white-space: nowrap;
        text-transform: uppercase;
      }
    }

    &-description {
      line-height: 12px;
      font-size: 10px;
      color: #444;
    }

    &-price {
      margin-top: 12px;
      line-height: 28px;
      font-size: 23px;
      font-weight: 600;
      color: #444;
    }
  }
}

.hover-actions {
  display: none;

  Button {
    width: 105px;
    border-color: white;
    padding: 2px 2px 4px 2px;

    svg {
      margin-top: -5px;
      font-size: 15px;
    }

    span {
      color: white;
      font-size: 9px;
    }
  }

  Button:hover {
    background-color: rgba(255, 255, 255, 0.3);

    span {
      color: white;
    }
  }
}

.image-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  min-width: 100%;
  min-height: 330px;
  max-width: 100%;
  border-radius: 16px;
  position: absolute;
  top: 0;
  box-shadow: 0 -1px 1px px rgba(0, 0, 0, 0.5);

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-image: linear-gradient(0, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 25%, rgba(255, 255, 255, 0) 50%);
    cursor: pointer;
  }

  .image-card-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 0 14px 22px 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;

    &-title {
      max-width: 100%;
      line-height: 20px;
      font-size: 16px;
      display: inline-flex;
      flex-flow: row nowrap;


      .name {
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
      }

      .type {
        white-space: nowrap;
        text-transform: uppercase;
      }
    }

    &-description {
      line-height: 12px;
      font-size: 10px;
    }

    &-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      line-height: 28px;
      font-size: 23px;
      font-weight: 600;
    }
  }


  Button {
    width: 105px;
    border-color: white;
    border-radius: 4px;
    padding: 2px 2px 4px 2px;

    svg {
      margin-top: -5px;
      font-size: 15px;
    }

    span {
      color: white;
      font-size: 9px;
    }
  }

  Button:hover {
    background-color: rgba(255, 255, 255, 0.3);

    span {
      color: white;
    }
  }
}

.ant-card-item:hover+.hover-actions,
.hover-actions:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  min-width: 100%;
  min-height: 330px;
  border-radius: 16px;
  position: absolute;
  top: 0;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    opacity: 0.5;
    background-color: black !important;
    cursor: pointer;
  }
}