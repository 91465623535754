.repository-item {
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: flex-start;
  border-style: solid;
  border-width: 0px 0px 0.5px;
  border-color: rgb(43, 52, 65);
  transition: all 200ms ease;
  width: 49%;
  min-height: 150px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 2%;
  h3 {
    color: white;
    margin-left: 10px;
  }
  &:hover {
    transition: all 0.1s;
    .img-wrap {
      & + div {
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.1s;
        padding-bottom: 15px;
      }
    }
    // div,
    // span {
    //   margin-bottom: 10px;
    //   transition: all 0.1s;
    // }
  }
}

.disabled-repository::before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background: white;
  cursor: not-allowed;
  z-index: 2;
}

.img-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  overflow: hidden;
  img {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
  }
  & + div {
    padding-bottom: 10px;
    transition: all 0.1s;
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.1s;
    background: rgb(255, 255, 255);
    background: rgb(255, 255, 255);
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 36%,
      rgba(0, 0, 0, 0.6362920168067228) 78%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 36%,
      rgba(0, 0, 0, 0.6362920168067228) 78%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 36%,
      rgba(0, 0, 0, 0.6362920168067228) 78%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
    div,
    span {
      margin-bottom: 0px;
      transition: all 0.1s;
    }
  }
}

.text-title {
  color: #fff;
  font-size: 20px;
  line-height: 1;
  padding-right: 5px;
  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.date {
  font-size: 10px;
  margin-left: 10px;
  color: white;
}

.description {
  margin-top: 5px;
  margin-bottom: -3px;
  font-size: 14px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: 400;
}

.text-subtitle {
  margin-top: 3px;
  margin-left: 10px;
  color: #ffffff;
  font-size: 12px;
}

.plus-btn {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #2b3441;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 3px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &:hover {
    background-color: #2176ff;
  }
}

.delete-btn {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 3px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fa5656;
}
