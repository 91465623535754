.palette-menu {
  padding: 5px;
  background-color: #1f2a40;
  width: 400px;
  color: #ccc;
  border-radius: 4px;

  .palette-item {
    display: flex;
    align-self: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #324365;
    font-weight: 500;
    margin: 5px 0;
    justify-content: space-between;

    &:hover {
      background-color: #283756;
    }

    .preview {
      min-width: 100px;
      width: 150px;
      height: 20px;
      border-radius: 2px;
    }

    span {
      margin-left: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .selected {
    background-color: #064bd973 !important;
    color: #fff;
  }

  .intervals {
    display: flex;

    .interval {
      flex: 1;
      padding: 6px 0;
      border-radius: 4px;
      background-color: #324365;
      margin: 2px;
      cursor: pointer;
      text-align: center;
      font-weight: 500;

      &:hover {
        background-color: #283756;
      }
    }
  }
}
