.sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 52px;
  position: relative;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  background-color: transparent;
  .poster-mode {
    display: flex;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: inset 0 0 1px 1px rgb(255 255 255 / 30%);
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    button {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin: 0;
      svg {
        color: white;
        font-size: 14px;
      }
    }
  }
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
  display: none;
}
