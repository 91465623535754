.map-template-layer-wrapper {
  margin-bottom: 15px;
  margin-top: 1rem;
  h3 {
    text-transform: uppercase;
  }
  .range-wrapper {
    border: 1px solid #d8d8d8;
    margin: 0.5rem 0 1rem;
    border-radius: 15px;
    padding: 2rem;
    #FramesRange {
      margin-top: 0;
      .frames-range {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
  }
}
.template-scene-modal {
  width: 100% !important;
  overflow: hidden !important;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  input::placeholder {
    color: #ffffff;
    opacity: 0.3;
  }
  label {
    color: white;
    display: flex;
    align-items: center;
  }
  .subheader {
    cursor: default;
  }

  .modal-body {
    height: unset;
    flex: 1 1 auto;
    padding: 1em 2em 2em !important;
    h3 {
      color: white;
      text-transform: uppercase;
      font-weight: 300;
      margin-bottom: 1em;
      margin-top: 1em;
      font-size: 20px;
    }
    .react-datepicker__input-container {
      button {
        width: 100%;
        margin: 1rem 0 1rem 0;
      }
    }
    .location-wrapper {
      margin-bottom: 1rem;
      label {
        margin-top: 0 !important;
        text-transform: uppercase;
      }
    }
    .stations {
      .station {
        padding: 1rem 0;
      }
    }
    // textarea,
    // input:not([type='checkbox']) {
    //   border: none !important;
    //   background: transparent;
    //   border-bottom: 1px solid #273c59 !important;
    //   border-radius: 0 !important;
    //   text-align: right;
    //   &:hover {
    //     border-color: #273c58 !important;
    //   }
    //   &:focus {
    //     border-color: #2176ff !important;
    //     box-shadow: none;
    //   }
    //   &:focus-visible {
    //     outline: none;
    //   }
    // }
    // select {
    //   border: none;
    //   background-color: transparent;
    //   border-bottom: 1px solid #273c59 !important;
    //   border-radius: 0;
    //   text-align: left;
    //   padding-right: 35px;
    //   padding-left: 10px;
    //   color: #ffffff;
    //   height: 32px;
    //   padding-top: 0;
    //   padding-bottom: 0;
    //   min-height: unset;
    //   font-size: 14px;
    //   margin-left: auto;
    //   &:hover {
    //     border-color: #273c58;
    //   }
    //   &:focus {
    //     border: none;
    //     border-bottom: 1px solid #2176ff;
    //     box-shadow: none;
    //   }
    // }
    // option {
    //   background: #3a4d61;
    // }
  }

  .selected-date-and-time {
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .footnote {
      padding-left: 10px;
      display: flex;
      align-items: flex-end;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      width: -webkit-fill-available;
    }
    button {
      margin: 0;
      margin-right: 0.5rem;
      svg {
        margin-right: 10px;
      }
    }
    .button-secondary {
      svg {
        path {
          color: #5a5a5a;
        }
      }
    }
  }
}

.geo-posters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  .repository-item {
    width: 49% !important;
  }
}
