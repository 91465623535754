.secondaryControls {
  display: flex;
  height: 29px;
  padding: 5px 0;
  // border-bottom: 0.5px solid #2b3441;
  // justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;

  .buttonControls,
  .timeDisplay,
  .modeSwitcher {
    display: flex;
    justify-content: space-between;
    // flex: 1 0 33.3333%;
    z-index: 30;
    font-variant-numeric: tabular-nums;
  }
  .secondaryControlsCanvas {
    padding: 8px 30px;
    border-radius: 20px;
    background: #80808063;
    height: auto;
    display: flex;
    width: 30%;
    flex-direction: column;
    position: fixed;
    bottom: calc(100px);

    &.idle {
      opacity: 0;
      transition: all 1s;
    }
    .lowerPlayer {
      display: flex;
      align-items: center;
      width: 100%;
      .slider {
        flex-grow: 1;
        cursor: pointer;
      }
      .numberContainer {
        width: 6rem;
        text-align: center;
        color: white;
        font-size: 0.8rem;
      }
    }
    .progress {
      width: 100%;
      height: 2px;
    }
  }
  .modeSwitcher {
    justify-content: flex-end;
    .btnSeq {
      margin-right: 5px;
    }
    .btnSeq,
    .btnSto {
      color: white;
      width: 40px;
      border-top: 2px solid #06152b;
      text-align: center;
      cursor: pointer;
      &:hover,
      &.active {
        border-top-color: #3a36db;
        font-weight: 700;
      }
    }
  }
  .timeDisplay {
    font-size: 14px;
    color: white;
    align-items: center;
    user-select: none;
    flex: 1 1 auto;
    padding: 0;
    span {
      font-variant-numeric: tabular-nums;
      color: #99b2c6;
      font-size: 14px;
      &:first-child {
        color: #ffffff;
      }
    }
  }
  .hoverState {
    font-size: 12px!important;
    span {
      font-size: 12px!important;
    }
    &:hover {
      // background-color: rgb(28, 25, 134);
      background-color: #2176ff;
      path {
        fill: #ffffff;
      }
    }
  }
  path {
    color: #ffffff;
  }
}

.saveBtn {
  opacity: 1;
  span {
    margin-left: 0 !important;
  }
  .redNotification {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fa5656;
    top: 5px;
    right: 5px;
  }
}
.controlBarInControl {
  position: absolute;
  justify-content: flex-end;
  overflow: hidden;
  display: flex;
  top: 20px;
  right: 40px;
  z-index: 30;

  .fullscreenIcon {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 5px;
  }
}
