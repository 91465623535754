.marketplace-modal {
  .error {
    border: 1px solid red !important;
  }
  .form-body {
    overflow: auto;
    max-height: 550px;
    overflow-x: hidden;
    padding: 10px 20px;
    padding-bottom: 10px;
    margin: 10px 0 10px;
    .calendar-input {
      position: relative;
      .calendar-icon {
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
    input {
      height: 32px;
      background-color: transparent;
      font-size: 14px;
      padding: 4px 11px;
    }
    label {
      font-weight: 400;
      margin-bottom: 5px;
      display: block;
      margin-top: 5px;
    }
    .upload-grid {
      display: grid;
      grid-template-columns: 30% 30% 30%;
      column-gap: 3%;
      row-gap: 3%;
      margin-left: 30px;
      &:empty {
        display: none;
      }
      .uploaded-image {
        background: white;
        overflow: hidden;
        border: 0.5px solid rgba(126, 126, 255, 0.8);
        width: 180px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .download {
          pointer-events: bounding-box;
          cursor: pointer;
          z-index: 1;
          position: absolute;
          background-color: #dc4444;
          padding: 3px;
          left: 10px;
          bottom: 10px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 15px;
            height: 15px;
            path {
              color: white;
            }
          }
        }
      }
    }
    .thumbnail-grid-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      // gap: 30px;
      margin-bottom: 10px;
    }
    .thumbnail-grid {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      flex-wrap: wrap;
      .col-item {
        position: relative;
        border: 0.5px solid rgba(126, 126, 255, 0.8);
        cursor: grab;
        width: 180px;
        .download {
          pointer-events: bounding-box;
          cursor: pointer;
          z-index: 1;
          position: absolute;
          background-color: white;
          padding: 3px;
          left: 10px;
          bottom: 10px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      & + .container {
        // gap: 30px;
        width: auto;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-start;
        //margin-left: 30px;
      }
    }
    & + div {
      padding: 20px;
    }
  }
  .p-6 {
    padding: 0;
  }
  .border-b.p-5 {
    padding: 20px 20px 10px 20px;
  }
  .dropzone {
    width: 180px;
    height: 120px;
    padding: 15px;
    font-weight: 100;
    opacity: 1;
    border: 1px dashed rgba(51, 51, 51, 0.3);
    border-radius: 16px;
    margin-bottom: 0;
    margin-left: 30px;
    color: #000000;
    cursor: pointer;
    & + .thumbnail-grid {
      max-width: unset;
    }
    &:hover {
      p,
      div.absolute {
        opacity: 1;
      }
    }
    p {
      opacity: 0.5;
    }
    div.absolute {
      bottom: 10px;
      right: 10px;
      opacity: 0.5;
      svg {
        font-size: 30px;
        path {
          color: #2176ff;
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  & + svg {
    font-size: 16px;
    width: 16px;
    opacity: 0.7;
    margin-right: 5px;
  }
}
.uploaded-image {
  background: white;
  overflow: hidden;
  border: 0.5px solid rgba(126, 126, 255, 0.8);
  width: 180px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .download {
    pointer-events: bounding-box;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    background-color: #dc4444;
    padding: 3px;
    left: 10px;
    bottom: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 15px;
      height: 15px;
      path {
        color: white;
      }
    }
  }
}

.render-status {
  position: relative;
  svg {
    margin-right: 0 !important;
  }
  .notification {
    position: absolute;
    color: white;
    right: 2px;
    top: -4px;
    background-color: #fa5656;
    min-width: 13px;
    padding: 0 3px;
    height: 13px;
    border-radius: 30%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .relative.z-20 {
    white-space: nowrap;
  }
}
