.ws-user-wrapper {
  position: relative;
  margin-left: 15px;
  .ws-user {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    img {
      height: 40px;
      width: 40px;
      object-fit: contain;
      border-radius: 100%;
      border: 5px solid #2176ff;
    }
  }
  .ws-user-dropdown {
    z-index: 3;
    position: absolute;
    background: white;
    border-radius: 20px;
    right: 0;
    top: 45px;
    box-shadow: 0 0 9px -5px rgba(0 0 0 / 75%);
    min-width: 200px;
    overflow: hidden;
    .ws-user-item {
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 1.3rem;
      &:first-child {
        background: #f3f3f3;
      }
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
          margin-right: 7px;
          margin-bottom: -1px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      &:last-child {
        border: none;
      }
      &:first-child {
        p {
          font-weight: 500;
          cursor: unset;
          //   color: #3a36db;
          &:hover {
            opacity: unset;
          }
        }
      }
    }
  }
}
