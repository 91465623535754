@import "../../assets/css/colors.scss";

.footer {
  display: flex;
  flex-direction: column;
  background-color: $dark;
  width: 100%;

  .footer-wrapper {
    display: flex;
    width: 85%;
    align-items: center;
    max-width: 85%;
    margin: 20px auto 0;

    img {
      width: 250px;
      height: 60px;
      object-fit: contain;
    }
  }

  .bottom-wrapper {
    display: flex;
    width: 85%;
    align-items: center;
    max-width: 85%;
    margin: 0 auto 20px;
    justify-content: space-between;
    div {
      img {
        height: auto;
        object-fit: contain;
        width: auto;
        margin-right: 15px;
      }
    }
    @media(max-width: 500px) {
      flex-direction: column;
      .icons {
        margin-top: 10px;
        margin-left: 0;
        gap: 5px;
      }
    }
 
  }

  .title {
    text-transform: uppercase;
    font-size: 15px;
    color: $light;
    font-weight: 700;
  }
  ul {
    margin-top: 15px;
    li {
      color: $light;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
  .lists {
    display: grid;
    width: 85%;
    align-items: center;
    max-width: 85%;
    grid-template-columns: auto auto auto auto;
    padding: 50px 0 140px 0;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    align-self: center;
    margin: 20px 0;
  }
}
