@import '../../assets/css/colors.scss';

.mp-bg-wrapper {
  height: 500px;
  width: 100%;
  position: relative;
  @media(max-width: 500px) {
    height: 400px;
  }

   & video {
    min-width: 100%;
    object-fit: cover;
  }
  & .overlay {
    width: 100%;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 2;
    @media(max-width: 500px) {
      height: 400px;
    }
  }
  
  .mp-bg {
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    h1 {
      text-align: center;
      color: white;
      font-size: 40px;

      @media(max-width: 500px) {
        font-size: 36px;
      }

      & span {
        color: #2176FF;
      }
    }
    h2 {
      color: white;
      text-align: center;
      font-weight: 200;
      margin: 0;
      @media(max-width: 500px) {
        font-size: 16px;
        max-width: 90%;
      }
      & b {
        font-weight: 500;
      }
      
    }
  }
  & .search {
    margin-top: 45px;
    width: 40%;
    @media(max-width: 500px) {
      width: 90%;

    }
    & input {
      padding: 12px 0 12px 15px;
      @media(max-width: 500px) {
        width: 100%;
      }
    }

    & input::placeholder {
      font-size: 18px;
      color: #AEAEAE;
    }
  }
}

.d-assets-description {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0px 2rem 20px;
}
.header.d-assets-description {
  max-width: unset;
}
.tabs-sorting-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media(max-width: 500px) {
    margin-bottom: 0;
  }
}
