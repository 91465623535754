.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.section-wrapper {
  .lpbackground:nth-child(2) {
    .images {
      img {
        position: relative;
        top: 50px;
      }
    }
  }
  .lpbackground:nth-child(3) {
    .images {
      img {
        position: relative;
        top: 100px;
      }
    }
  }
  .lpbackground:nth-child(4) {
    .images {
      img {
        position: relative;
        top: 150px;
      }
    }
  }
  .lpbackground:nth-child(5) {
    .images {
      img {
        position: relative;
        top: 200px;
      }
    }
  }
}
footer {
  margin-top: 5rem;
}
.special-offer-carousel {
  .css-doq0dk {
    .css-1fzpoyk {
      width: 50%;
      div {
        border-radius: 16px;
        overflow: hidden;
        h2 {
          padding: 5px 10px;
          background-color: white;
        }
        p {
          padding: 5px 10px;
          background-color: white;
        }
      }
    }
  }
}