.ws-card-speed-dial {
  z-index: 1;
  top: 10px;
  right: 10px;
  visibility: visible;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .ws-card-speed-dial-icon {
    color: rgb(120, 120, 120);
    font-size: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: transform 0.2s;
    margin: 0;
    &.dial-open {
      transform: rotate(90deg);
    }
  }
}

.ws-card-speed-dial {
  cursor: pointer;
  position: absolute;
  visibility: hidden;
}
.ws-action-list {
  max-height: 0;
  display: none;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 40px;
  border: 1px solid rgba(204, 204, 204, 0.4);
  background-color: white;
  border-radius: 5px;
  &:empty {
    display: none;
  }
  ul {
    text-align: right;
    li {
      display: flex;
      span {
        text-align: left;
        justify-content: flex-end;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        border-radius: 5px;
        padding: 7px 12px 7px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        gap: 10px;
        white-space: nowrap;
        svg {
          width: 15px;
          font-size: 15px;
        }
        &:hover {
          background-color: rgb(245, 244, 244);
        }
      }
    }
  }
  &.action-open {
    max-height: 400px;
    display: block;
    overflow: visible;
  }
}
