@import '../../assets/css/colors.scss';

.button {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 10px 30px;
  height: 32px;
  .label {
    font-weight: 400;
    margin: 0;
  }
  &.button-link {
    background: none;
    box-shadow: none;
    color: black;
    padding: 0;
  }
  &.button-danger {
    background-color: #fa5656;
    span {
      color: white;
    }
  }
  &.button-border {
    background-color: transparent;
    border: 1px solid rgba(6, 21, 43, 0.5);
  }
  &.button-primary {
    background-color: $primaryBlue;
    box-shadow: none;
    .label {
      color: $snow;
    }
    &:hover {
      background-color: $primaryPurple;
    }
    &.button-rounded {
      padding: 5px;
      width: 40px;
      height: 40px;
      background-color: $lightGrey;
      .label {
        color: $primaryGrey;
      }
      &:hover {
        background-color: $primaryGrey;
        .label {
          color: $lightGrey;
          svg {
            color: $lightGrey;
          }
        }
      }
    }
  }
  &.button-buy {
    background-color: $primaryBlue;
    box-shadow: none;
    padding: 5px;
    width: 40px;
    height: 40px;
    .label {
      color: $snow;
    }
    svg {
      fill: $white !important;
    }
    &:hover {
      background-color: $primaryPurple !important;
    }
  }
  &.button-secondary {
    background-color: $snow;
    border: 1px solid $primaryGrey;
    box-shadow: none;
    .label {
      color: $darkText;
    }
    span {
      color: $darkText;
    }
    &:hover {
      background-color: darken($color: $snow, $amount: 5%);
    }
    &.button-rounded {
      padding: 5px;
      width: 40px;
      height: 40px;
      background-color: $lightGrey;
      border: none;
      .label {
        color: $primaryPurple;
      }
      &:hover {
        background-color: $primaryPurple;
        .label {
          color: $snow;
          svg {
            color: $snow;
          }
        }
      }
    }
  }
  &.button-square {
    padding: 6px 32px;
    height: 33px;
    background-color: $darkGrey;
    border-radius: 0.5rem;
    &:disabled {
      opacity: 0.3;
    }
    .label {
      color: $darkText;
      font-weight: 400;
      font-size: 16px;
    }
    &:hover,
    &.selected {
      background-color: $secondaryPurple;
      .label {
        color: $white;
      }
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
