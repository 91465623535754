.wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-delay: 2s;
  transition: 0.3s;
  position: absolute;
  width: 117px;
  // left: calc(-117px - 0.5rem);
  text-transform: uppercase;
  margin-right: 0;
  top: 1px;
  pointer-events: none;
  &:hover {
    span {
      opacity: 0.8;
    }
  }
  span {
    background-color: #fa5656;
    color: white;
    padding: 2px 15px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    pointer-events: all;
    height: 23px;
    font-size: 12px;
  }
}
.disabled {
  opacity: .5;
  &:hover {
    opacity: .5 !important;
  }
}
