.container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100vh;
  width: 90%;
}

.table-component {
  max-height: 70vh;
  overflow-y: scroll;
}


.table-component .table-auto {
  width: 100%;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
  margin-bottom: 24px;
}





