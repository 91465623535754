@import '../../../../assets/css/colors.scss';

.image-preview-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  border-radius: 10px;
  background: white !important;
  width: 140px !important;
  height: 175px !important;
  padding: 5px;
  &.checked {
    box-shadow: inset 0px 0px 0px 1px #2176ff;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }

  &__name {
    font-size: 12px;
    padding: 4px;
    overflow-wrap: break-word;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__buttons {
    display: none;
    justify-content: center;
    gap: 3px;
    height: max-content;
    padding-top: 3.5px;

    .button {
      padding: 0;
      width: 30px !important;
      height: 32px !important;
      margin: 0;

      &-link {
        border: 1px solid lightgray;
      }
      &-danger {
        color: white;
      }
    }
  }
  &:hover {
    .image-preview-card__buttons {
        display: flex;
    }
    .image-preview-card__name {
        display: none;
    }
  }

  input[type='checkbox'] {
    position: absolute;
  }
}
.card {
  &-default {
    border: 1px solid #f0f0f0;
  }
  &-danger {
    border: 1px solid #fa5656;
  }
  .button-danger {
    color: white;
  }
}