.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 0;
  position: relative;
  background-color: #06152b;
  min-height: 100%;
  border: 0.5px solid #2b3441;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 20px;

  label {
    margin-left: 10px;
    //width: 100px;
    color: white;
  }

  &::-webkit-scrollbar {
    z-index: 999;
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #2b3441;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: white;
  }
}

.inputWrap {
  display: flex;
  padding: 5px;
  width: 100%;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(103, 130, 153, 0.54);
  font-size: 14px;
  color: white;
  input {
    background-color: rgba(103, 130, 153, 0.54);
  }
}

.selectWrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;

  label {
    margin-right: 5px;
  }

  div {
    width: 100% !important;
  }
}
.select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 24px;
  min-height: 32px;
  background-color: rgba(103, 130, 153, 0.54);
  border-color: #6b7280;
  color: #8e8e8e;
  border-radius: 6px;
  option {
    background: #3a4d61;
  }
}

.action-btn {
  border-radius: 10px;
  background-color: #e5e7eb;
  padding: 5px;
  color: #050f1e;
  justify-content: center;
}

.colorPicker {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100%;
  overflow: visible;
  border-radius: 6px;
  width: 100%;
  .colorPickerWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    div {
      border: 1px solid #3e5879;
    }
    .inputWrap {
      background-color: #060b12;
      border: 1px solid #2b3441;
      border-radius: 2px;
      padding: 0 5px;
      margin-left: 0.5rem;
      height: 25px;
    }
    input[type='color'] {
      -webkit-appearance: none;
      border: none;
      height: 34px !important;
      width: 34px;
      border-radius: 100px;
    }
    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
      border-radius: 100%;
      display: none;
    }
    input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 0 6px 6px 0;
    }
  }
}
.chromePicker {
  position: absolute;
  // margin-left: 200px;
  top: 32px;

  label {
    width: 100% !important;
  }
  .colorPickerFooter {
    justify-content: right;
    background-color: white;
    width: 225px;
    display: flex;
    padding: 10px;
  }
}

.thumbnailsWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  div {
    position: absolute;
    top: 3%;
    right: 3%;
    background: #333333aa;
    color: white;
    margin-bottom: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
  }
  img {
    width: 45px;
    height: 45px;
    margin: 15px 15px 15px 0;
    // border-radius: 12px;
    // border: 3px solid transparent;
    &.active {
      background-color: white;
    }
  }
  .active {
    width: 45px;
    height: 45px;
    margin: 15px 15px 15px 0;
    border: 3px solid #f9b959;
    border-radius: 12px;
  }

  &:hover > div {
    visibility: visible;
    opacity: 0.7;
    font-size: 14px;
  }
}

.table-wrap {
  div[data-testid='table-element'] {
    overflow: hidden;
  }
}
.paletteModal {
  .modal-body {
    height: calc(80% - 50px - 1rem);
  }
}
.paletteGrid {
  display: grid;
  grid-template-columns: 20% 5% 70% 5%;
}
.colorPickerPalette {
  display: flex;
  max-width: 100%;
  align-items: center;
  position: relative;
  height: 50px;
  overflow: visible;
  border-radius: 6px;
  width: 100%;
  .indicator {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 100%;
    border: 1px solid white;
    margin-right: 1rem;
  }
  .colInput {
    width: 2.5rem;
    text-align: center;
    padding: 0;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      input {
        padding: 0;
      }
    }
    &.r {
      color: #ff4b4b;
    }
    &.g {
      color: #25ba25;
    }
    &.b {
      color: #4280ff;
    }
  }
  .palettePickerWrapper {
    position: absolute;
    z-index: 1;
  }
}
.iconDD{
  height: 30px;
  width: 30px;
  img{
    width: auto!important;
    margin: auto;
  }
}
