.ant-input::placeholder {
    color: gray;
    opacity: 1;
  }
  .ant-input-number-input:placeholder-shown::placeholder{
    color: gray;
    opacity: 1;
  }

.ant-picker .ant-picker-input >input::placeholder {
  color: gray;
  opacity: 1;
}