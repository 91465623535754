.items {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0;
    border-color: rgba(141, 148, 159, 0);
    border-radius: 0;
    transition: all 200ms ease;
    color: #fff;
    text-decoration: none;

    .item {
      display: flex;
      width: 40px;
      height: 40px;
      margin-top: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      box-shadow: inset 0 0 1px 1px rgb(255 255 255 / 30%);
      background-color: transparent;
      color: #fff;
      svg {
        color: white;
      }
      &.opened {
        background-color: #2176ff;
      }
    }
  }
}
.alignment-buttons {
  display: grid;
  padding: 5px;
  margin: auto;
  button{
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    background-color: rgba(255, 255, 255, 0.35);
    color: black;
  }
}
