.map-properties-wrapper {
  .layer-info-wrapper {
    border: 1px solid transparent;
    border-radius: 5px;
    width: calc(100% - 15px);
  }
  .accordion-header {
    background-color: rgb(7 30 54);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);

    .subheader {
      border-bottom: none;
      margin: 10px 0;
      & + svg {
        margin-right: 10px;
      }
    }
  }

  .subheader {
    margin-bottom: 0;
  }
  .layers-wrapper {
    &:empty {
      display: none;
    }
    div.layers {
      .accordion-body {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          h4 {
            margin-top: 0;
          }
        }
        button.bg-red-700 {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
    .prop-button-w100 {
      gap: 10px;
      button {
        margin: 0;
      }
    }
  }
}

.layers-wrap {
  input {
    color: white;
    text-align: left !important;
    height: 32px !important;
    &:focus {
      border-color: #2176ff;
      outline: none;
      box-shadow: none;
    }
  }
  input::placeholder {
    color: white;
    opacity: 0.3;
  }
  .layer-item-flex-wrapper {
    display: flex;
    align-items: center;
    gap: 3em;
    &.modal-palette {
      .palette-legend {
        canvas {
          max-width: 300px;
        }
      }
    }
    .layer-item {
      width: 50%;
    }
  }
  .grid-item {
    height: 32px;
  }
  .layer-item {
    margin-bottom: 2em;
    select {
      height: 32px;
    }
    label {
      margin-bottom: 0 !important;
      span {
        color: #fa5656;
      }
    }
  }
  .z-index {
    div {
      max-width: 200px;
      margin: 0 auto;
    }
    input {
      max-width: 200px;
    }
  }
}

.confirmation-modal {
  button {
    font-size: 15px;
    padding: 10px 30px;
    height: 40px;
    white-space: nowrap;
    svg {
      color: white;
    }
  }
}
.confirmation-modal.dark {
  background-color: #1f2a40;
  .dark:bg-gray-700 {
    background-color: #1f2a40;
  }
}

.basic-properties {
  .grid.prop-grid-4 {
    margin: 2rem 0 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .layer-header {
    padding-left: 0;
    margin-top: 1rem;
  }
  .grid-item {
    background-color: transparent;
  }
}

.hor-ver-switcher {
  & + button {
    .toggle-bg {
      background-color: transparent !important;
      border-color: #1a56db !important;
    }
  }
}

.CustomPalette__grid-wrapper {
  .grid-item-label {
    display: flex;
    align-items: center;
  }
}
.model-layer{
  .ant-slider-handle {
  top: -1px;
}
}
