@import "../assets/css/colors.scss";

:root {
  font-size: 14px;
  background-color: #d9d9d9 12%;
  user-select: none;
}

h1 {
  font-size: 2.8rem;
  line-height: 3.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2.3rem;
  line-height: 3rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba($bigFont, 0.75);
}

.doc-title {
  font-weight: 600;
}

.doc-text {
  text-transform: capitalize;
  font-style: italic;
}

.ant-dropdown-search {
  min-width: 140px;
  padding: 0 10px;
  border-right: 1px solid #06152b3d;
  font-size: 1.2rem;
  color: #06152b8c;
  display: flex;
  justify-content: space-between;
}

div {
  vertical-align: middle !important;
}

.ant-dropdown-menu {
  border-radius: 0 !important;
  background-color: #FAFAFA !important;

  &-item {
    padding: 0 3px !important;
    border-radius: 0 !important;
    font-size: 1.2rem !important;
    color: #06152b8c !important;
  }
}

.ant-avatar {
  background-color: #F5F5F5;
  color: #2D2D2D;
}

.ant-card {
  &-head {
    padding: 0 !important;
    border-bottom: none !important;
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    color: #445060 !important;
  }

  &-body {
    padding: 15px 0 30px 0 !important;
  }

  &-actions {
    border-top: none !important;
  }
}

.ant-card-create {
  Button {
    width: 100% !important;
  }
}

.tag-flex-container {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 6px;
}

.top-filters-flex-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 6px;

  .sorting-wrapper-container {
    margin-left: auto;
    @media(max-width: 500px) {
      margin: 0;
    }
  }
}
