.wrapper {
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    justify-self: center;
    flex-direction: column;
    width: 100%;

    .button {
      svg {
        fill: #888888;
      }

      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }

    .name {
      font-size: 32px;
      font-weight: 700;
      color: rgba(6, 21, 43, 0.75);
    }

    img {
      max-width: 100%;
      max-height: 450px;
      object-fit: cover;
      border-radius: 15px;
    }
  }

}
