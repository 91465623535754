.ws-details {
  width: 100%;
  .ws-active-thumb,
  .ws-list-thumb {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    background-color: #f0f0f0;
    border-radius: 20px;
    margin: auto;
  }
  .ws-thumb-list {
    display: flex;
    width: 100%;
    margin: auto;
    overflow-x: auto;
    .ws-list-thumb {
      height: 100px;
      margin: 20px 0 0 0;
      cursor: pointer;
    }
  }
  .ws-central {
    display: flex;
    margin-top: 1em;
    .ws-details-textarea {
      margin-right: 20px;
      width: 50%;
      min-width: 50%;
      max-width: 50%;
    }
    .ws-tag-area {
      display: flex;
      flex-direction: column;
      .ws-tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        .ws-tag {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 20px;
          padding: 3px 7px;
          color: rgb(255 255 255);
          border: 1px solid rgba(0, 0, 0, 0.1);
          gap: 5px;
          background-color: #1f2937;
          text-transform: capitalize;
          svg {
            margin-right: 0 !important;
          }
          .ws-tag-remove {
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .ws-active-thumb {
    width: 100%;
    transition: background 0.1s linear;
  }

  .ant-select-selecctor{
    height: 100% !important;
  }
}

.template-tabs {
  button {
    border-radius: 0;
    font-size: 1.3rem;
    text-transform: uppercase;
  }
  .active {
    outline: none;
  }
}
