.bias-filter {
  background-color: #06152b;
  min-height: 100%;
  border: 0.5px solid #2b3441;
  position: relative;
  z-index: 2;
}
.bias-input {
  padding: 0 5px;
  color: black;
  border-radius: 4px;
  height: 2rem;
  &:disabled{
    color: white;
    background-color: transparent;
    border: none;
  }
}
.icon-val-container{
  img{
    width: auto!important;
    margin: auto;
  }
}

.grid-icon{
  img{
    width: auto!important;
  }
  .lottie{
    display: contents;
  }
}
