@import '../../assets/css/colors.scss';

.heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  color: rgba(6, 21, 43, 0.75);
  background-color: white;
  position: sticky;
  top: -30px;
  button {
    .label {
      gap: 10px;
    }
  }
  input {
    width: 100%;
  }
}
