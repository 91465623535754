.prop-grid-4,
.volume-prop-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 'labelArea . . .';
  column-gap: 4px;
  row-gap: 7px;
  margin-right: 10px;
  .labelArea {
    grid-area: labelArea;
  }
  .padding-grid {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 1px;
    row-gap: 3px;
  }
}
.volume-prop-grid {
  grid-template-columns: 20% 26% 4% 25% 25%;
}
.active {
  background-color: white;
  outline: 1px solid rgb(80 157 80);
  box-sizing: border-box;
  border-radius: 4px;
}
.span-2-rows {
  grid-row: span 2;
}
.grid-item {
  height: 32px;
  background-color: #06152b;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  //   input {
  //     height: 100% !important;
  //   }
  label {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .flex {
    width: 100%;
  }
  div[role='group'] {
    width: 100%;
  }
  &.map-types {
    div[role='group'] {
      width: 100%;
      button {
        padding: 0;
        background-color: transparent;
        span {
          padding: 0;
        }
      }
      .focus\:ring-4:focus {
        --tw-ring-offset-shadow: none;
        --tw-ring-shadow: none;
        box-shadow: none;
      }
      &:focus {
        --tw-ring-shadow: 0 !important;
      }
    }
  }
}

.subheader,
.subheader-1 {
  font-weight: 300;
  color: rgb(255 255 255);
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  text-transform: uppercase;
  padding-left: 10px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    svg {
      opacity: 1;
    }
  }
  svg {
    opacity: 0.5;
    margin-right: 7px;
    font-size: 12px;
  }
}
.subheader-1 {
  font-style: italic;
  font-size: 0.8rem;
  border-bottom: none;
  text-decoration: underline;
}
.property-grey-actions-wrapper {
  right: 10px;
  top: 10px;
  div[data-testid='tooltip'] {
    .relative {
      & + div {
        top: 8px !important;
      }
    }
  }
  button {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #06152b;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 22px;
    border-radius: 5px;
    transition: all 200ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    line-height: 1;
  }
}
.property-grey-buttons {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #06152b;
  border: 1px solid rgb(43 52 65) !important;
  height: 22px;
  border-radius: 5px;
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  line-height: 1;
  &:hover {
    border: 1px solid rgb(72, 83, 98) !important;
  }
}

.subheader {
  margin-bottom: 0;
  margin-top: 5px;
  &:first-child:not(.no-padding-top) {
    margin-top: 1rem;
  }
}

.sub-tab-wrapper {
  padding-top: 0.5rem;
  padding-left: 1.3rem;
  &:empty {
    display: none;
  }
  .subheader {
    margin-top: 0;
  }
  .layer-header {
    button {
      margin-left: 1.3rem;
    }
  }
}

.save-as-template-wrapper {
  background: #1f2a40;
  color: white;
  .thumb-wrap:empty {
    display: none;
  }
  .modal-header {
    div {
      padding: 10px 20px;
      border-color: #171f30;
    }
    & + .p-6 {
      padding: 10px 20px;
      & + div {
        padding: 10px 15px;
        border-color: #171f30;
      }
    }
  }
  h3,
  label,
  p {
    color: white;
  }
  input {
    background-color: transparent;
    height: 38px;
    border: 1px solid rgb(77, 86, 104);
    color: white;
  }
}

.thumb-wrap {
  flex-wrap: wrap;
  gap: 10px;
  .thumb {
    width: 165px;
    height: 105px;
  }
}

.take-sc-upload-img {
  width: 165px;
  height: 105px;
  border: 1px solid rgba(51, 51, 51, 0.9);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    opacity: 0.5;
    font-size: 12px;
  }
  button {
    padding: 5px 15px;
    height: 32px;
    border-color: #efefef !important;
    &:hover {
      border-color: unset !important;
    }
  }
}
