@import 'src/assets/css/colors';
.container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #bcbcbc;
  height: fit-content;
  width: auto;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  margin-right: 0.5rem;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: black;
  border-radius: 1px;
  border: 0.5px solid white;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: $secondary;
}
.container.disabled {
  input ~ .checkmark {
    box-shadow: none;
    background-color: red !important;
  }
  &:hover {
    input ~ .checkmark {
      background-color: red !important;
    }
  }
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  display: none;
}
.checkmark:hover {
  background-color: #1a56db;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  position: relative;
  left: 5px;
  top: 0;
  width: 4px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
