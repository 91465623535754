.duration-modal {
  background: #1f2a40;
  color: white;
  div,
  h3,
  label,
  p {
    color: white;
  }
  input {
    background-color: transparent;
    height: 38px;
    border: 1px solid rgb(77, 86, 104);
    color: white;
  }
  .duration-modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    div {
      padding: 10px 20px !important;
    }
    & + div {
      padding: 10px 20px;
      & + div {
        padding: 10px 20px 1rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  .modal-footer {
    margin-top: 1rem;
    button {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  label {
    font-weight: 100;
  }
  .choose-duration {
    display: flex;
    align-items: center;
    height: 38px;
    flex-wrap: wrap;
    button {
      height: 38px;
      min-width: 38px;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      span {
        margin: 0;
        background-color: transparent;
        color: white;
      }
    }
  }

  .error-info {
    display: block;
    color: #ff6161;
    font-weight: 100;
    font-size: 12px;
    margin-top: 5px;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.2;
    color: white !important;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.2;
    color: white !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.2;
    color: white !important;
  }
  .button-link {
    padding: 0 0.5rem;
  }
}
