@import '../../assets/css/colors.scss';

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-out;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 0 ease-out;
}

.pagination {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;

  .pagination-item,
  .pagination-prev-next {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 6px;
    color: rgba($bigFont, 0.5);

    &:hover {
      background-color: $lightGrey;
      color: rgba($bigFont, 0.5);
    }
  }

  .pagination-active {
    background-color: $primaryBlue;
    color: white;
  }
}

.ant-empty-marketplace {
  .ant-empty-image {
    height: 60px !important;

    svg {
      transform: scale(2);
    }
  }

  .ant-empty-description {
    font-size: x-large;
  }
}

.ant-radio-group{
  padding-bottom: 32px;
}