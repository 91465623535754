.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 0;
  position: relative;
  min-height: 100%;
  border: 0.5px solid #2b3441;
  overflow-y: auto;
  padding-bottom: 20px;
  label {
    margin-left: 10px;
    color: white;
  }
}

.inputWrap {
  display: flex !important;
  padding: 5px !important;
  width: 100% !important;
  height: 23px !important;
  justify-content: flex-start !important;
  align-items: center !important;
  border-radius: 0px !important;
  background-color: rgba(103, 130, 153, 0.54) !important;
  font-size: 14px !important;
  color: white !important;
  input {
    background-color: rgba(103, 130, 153, 0.54) !important;
  }
}
.select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 24px;
  min-height: 23px;
  background-color: rgba(103, 130, 153, 0.54) !important;
  border-color: #6b7280;
  color: #8e8e8e;
  border-radius: 0px;
  padding: 0 5px !important;
  option {
    background: #3a4d61;
  }
}

.revert-btn {
  padding: 5px;
  border-radius: 100% !important;
  background-color: #fff;
  &:disabled {
    background-color: #8e8e8e;
  }
}
