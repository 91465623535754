.tags {
  display: flex;
  overflow-x: scroll;

  button {
    border: 0.5px solid rgba(163, 163, 163, 0.5) !important;
    border-radius: 20px !important;
    background-color: white !important;
    margin: 0 10px 30px 0;

    span {
      color: #a3a3a3 !important;
    }
  }

  .no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(217, 217, 217, 0.12);
    height: 40px;
    color: #445060;
    font-size: 16px;
    border-radius: 12px;
  }
}


.marketplace-tag {
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0;
  margin-inline-end: 0;
  background-color: #F3F3F3;
  border: none;

  &-big {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 14px;
    text-transform: uppercase;
    background-color: #F3F3F3;
    border: none;
  }
}